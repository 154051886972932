import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL query to get anime announcements
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements($genre: String!) {
    animes(genre: $genre, limit: 100, order: popularity) {
      id
      russian
      score
      episodes
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function GenreAll() {
    const { id: genreId } = useParams();  
    const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS, {
      variables: { genre: genreId },
    });
    const [expandedItem, setExpandedItem] = useState(null);
    const [serverData, setServerData] = useState([]);
    const [animeDetails, setAnimeDetails] = useState({}); // Для хранения данных из API
    const [loadingImages, setLoadingImages] = useState(true);
    const [loadingAnimeData, setLoadingAnimeData] = useState(true); // Новое состояние
    const [fetchedAnimeData, setFetchedAnimeData] = useState({}); // Новое состояние
    const [imageUrls, setImageUrls] = useState([]);

    const genres = {
        "4-Comedy": "Комедия",
        "22-Romance": "Романтика",
        "2-Adventure": "Приключения",
        "37-Supernatural": "Сверхъестественное",
        "8-Drama": "Драма",
        "36-Slice-of-Life": "Повседневность",
        "30-Sports": "Спорт",
        "14-Horror": "Ужасы",
        "24-Sci-Fi": "Фантастика",
        "1-Action": "Экшен",
        "10-Fantasy": "Фэнтези",
        "117-Suspense": "Триллер"
    };

    const currentGenreName = genres[genreId] || "Неизвестный жанр";

    const fetchServerData = async () => {
      try {
        const response = await axios.get('https://api.aniplay.fun/anime');
        setServerData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

  // Function to fetch data from the API
  const fetchAnimeData = async (animeId) => {
    try {
      const response = await axios.get(`https://api.aniplay.fun/storage/${animeId}/`);
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching anime data:', error);
      return null;
    }
  };
  
  // Загрузка изображений
  useEffect(() => {
    const fetchImages = async () => {
      if (!data || !data.animes) return;
  
      setLoadingImages(true);
      const urls = await Promise.all(
        data.animes.map(async (anime) => {
          const animeData = await fetchAnimeData(anime.id);
          return animeData ? animeData.sap : anime.poster.originalUrl;
        })
      );
      setImageUrls(urls);
      setLoadingImages(false);
    };
  
    fetchImages();
  }, [data]);
  
  // Загрузка данных аниме
  useEffect(() => {
    const fetchAllAnimeData = async () => {
      if (!data || !data.animes) return;
  
      setLoadingAnimeData(true);
      const animeDataPromises = data.animes.map(async (anime) => {
        const animeData = await fetchAnimeData(anime.id);
        return { id: anime.id, data: animeData };
      });
  
      const results = await Promise.all(animeDataPromises);
      
      const animeDataMap = results.reduce((acc, item) => {
        acc[item.id] = item.data;
        return acc;
      }, {});
  
      setFetchedAnimeData(animeDataMap);
      setLoadingAnimeData(false);
    };
  
    fetchAllAnimeData();
  }, [data]);

    const findAnimeOnServer = (id) => {
      return serverData.find((anime) => anime.id === Number(id));
    };

    const handleItemClick = (index) => {
      setExpandedItem(index === expandedItem ? null : index);
    };

    function renderScoreWithSVG(score) {
      if (score >= 8) {
        return <>{score}</>;
      } else {
        return <>{score}</>;
      }
    }

    if (loading || loadingImages || loadingAnimeData) return (
      <div className='all_header'>
        <div className='open_header'>
            <span>{currentGenreName}</span>
        </div>
        <div className='open_list'>
          {Array.from({ length: 21 }, (_, index) => (
            <div key={index} className='item_open_list' style={{ display: "block" }}>
              <div className='item_all loadbar_item_all'>
                <div className='loading-bar'></div>
              </div>
              <div className='overlay'></div>
              <div className="item_name loadbar_item_name">
                <div className='loading-bar'></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

    if (error) return <p>Error: {error.message}</p>;

    const animes = data?.animes || [];

    return (
      <div className='all_header'>
        <div className='open_header'>
            <span>{currentGenreName}</span>
        </div>
        <div className='open_list'>
          {animes.map((anime, index) => {
            const serverAnime = findAnimeOnServer(anime.id);
            const displayAnime = serverAnime || anime;

            const animeFetchedData = fetchedAnimeData[anime.id];

            return (
              <div key={anime.id} className='item_open_list'>
                <a href={`/anime/${anime.id}`}>
                  <div className='item_all'>
                    <div className='container_catalog_item_tag'>
                      {serverAnime && (
                        <div className='item_tag' style={{ backgroundColor: 'rgb(255, 63, 63)', color: 'white', fontWeight: '500' }}>4K</div>
                      )}
                      <div
                        className={`item_tag ${
                          displayAnime.score >= 8 ? 'hard_score' : displayAnime.score <= 7 ? 'low_score' : 'high_score'
                        }`}
                      >
                        {renderScoreWithSVG(displayAnime.score.toFixed(1))}
                      </div>
                    </div>
                    {!animeFetchedData && <div className='poster_overlay'></div>}
                    <img
                      src={imageUrls[index] || anime.poster.originalUrl} // Use the fetched URL or fallback to originalUrl
                      alt={anime.name}
                    />
                    {!animeFetchedData && (
                      <div className="item_name">{anime.russian || anime.name}</div>
                    )}
                    <div className='overlay'></div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
}
