import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AllOpenList from '../components/All=6';
import { Helmet } from "react-helmet";

export default class All extends Component {
    render() {
      return (
          <div>
            <Helmet>
              <title>Лучшие 2022-2023</title>
            </Helmet>
            <Header />
            <AllOpenList />
            <div style={{height: '70px'}}></div>
            <Footer/>
          </div>
      )
    }
}