import React, { Component } from 'react'
import Header from '../components/Header'
import Player from '../components/Player'

export default class play extends Component {
  render() {
    return (
      <Player />
    )
  }
}
