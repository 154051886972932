import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './components/apolloClient';
import Welcome from "./page/welcome";
import Anime from './page/anime';
import AllOngoing from './page/all=1';
import AllAnons from './page/all=2';
import AllMovie from './page/all=3';
import AllLates from './page/all=4';
import AllTop2024 from './page/all=5';
import AllTop2023 from './page/all=6';
import Play from './page/play';
import Profile from './page/profile';
import Auth from './page/auth';
import Settings from './page/settings';
import Agreement from './page/agreement';
import PrivacyPolicy from './page/privacy';
import PublicOffer from './page/public';
import Genre from './page/genre';
import Snowfall from './components/Snowfall';
import Player from './page/player';
import Collection from './page/collection';
import Flask from './page/flask';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);

    if (isMobile) {
      window.location.href = 'https://m.aniplay.fun';
    }

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [isMobile]);

  return (
    <ApolloProvider client={client}>
      <div className="aniplay.fun">
        <Routes>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/anime/:id" element={<Anime />} />
          <Route path="/all=1" element={<AllOngoing />} />
          <Route path="/all=2" element={<AllAnons />} />
          <Route path="/all=3" element={<AllMovie />} />
          <Route path="/all=4" element={<AllLates />} />
          <Route path="/all=5" element={<AllTop2024 />} />
          <Route path="/all=6" element={<AllTop2023 />} />
          <Route path="/old-play/:id" element={<Play />} />
          <Route path="/play/:id" element={<Player />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/public" element={<PublicOffer />} />
          <Route path="/genre/:id" element={<Genre />} />
          <Route path="/flask" element={<Flask />} />
          <Route path="*" element={<Navigate to="/welcome" replace />} />
        </Routes>
      </div>
    </ApolloProvider>
  );
}

export default App;
