import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

export default function Player() {
    const scrollContainerRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showEpisodes, setShowEpisodes] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(1);
    const [isHovering, setIsHovering] = useState(false);
    const [bufferedRanges, setBufferedRanges] = useState([]);
    const [currentTime, setCurrentTime] = useState(0);
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const hideControlsTimeout = useRef(null);
    const [tooltipTime, setTooltipTime] = useState(null);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const { id } = useParams();
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(search);
    const animeTitle = queryParams.get('title');
    const selectedEpisodeParam = queryParams.get('episode') || '1';
    const [source, setSource] = useState(queryParams.get('source') || 'AniLibria');

    const [animeData, setAnimeData] = useState(null);
    const [selectedEpisode, setSelectedEpisode] = useState(selectedEpisodeParam);
    const [availableResolutions, setAvailableResolutions] = useState([]);
    const [currentQuality, setCurrentQuality] = useState('');
    const [showSettings, setShowSettings] = useState(false);
    const settingsRef = useRef(null);
    const settingsIconRef = useRef(null);
    const listEpisodeRef = useRef(null);
    const episodeToggleIconRef = useRef(null);
    const [showQualitySettings, setShowQualitySettings] = useState(false);
    const qualitySettingsRef = useRef(null);
    const sourceSettingsRef = useRef(null);
    const [showSourceSettings, setShowSourceSettings] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    

    // Логика отображения тайм-кода на таймлайне
    const formatTime = (seconds) => {
        if (isNaN(seconds) || seconds < 0) return '00:00';
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return hrs > 0 
            ? `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}` 
            : `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    // Функции для открытия настроек качества и озвучки
    const handleShowQualitySettings = () => {
        setShowSettings(false); // Закрываем основное меню настроек
        setShowQualitySettings(true); // Открываем настройки качества
    };

    const handleShowSourceSettings = () => {
        setShowSettings(false); // Закрываем основное меню настроек
        setShowSourceSettings(true); // Открываем настройки озвучки
    };

    // Обработчики клика вне настроек качества и озвучки
    useEffect(() => {
        const handleClickOutsideQualitySettings = (event) => {
            if (qualitySettingsRef.current && !qualitySettingsRef.current.contains(event.target)) {
                setShowQualitySettings(false); // Закрываем настройки качества при клике вне
            }
        };
        
        const handleClickOutsideSourceSettings = (event) => {
            if (sourceSettingsRef.current && !sourceSettingsRef.current.contains(event.target)) {
                setShowSourceSettings(false); // Закрываем настройки озвучки при клике вне
            }
        };
        
        if (showQualitySettings) {
            document.addEventListener('mousedown', handleClickOutsideQualitySettings);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideQualitySettings);
        }

        if (showSourceSettings) {
            document.addEventListener('mousedown', handleClickOutsideSourceSettings);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideSourceSettings);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideQualitySettings);
            document.removeEventListener('mousedown', handleClickOutsideSourceSettings);
        };
    }, [showQualitySettings, showSourceSettings]);

    // Функция возврата к основным настройкам
    const handleBackToSettings = () => {
        setShowQualitySettings(false); // Закрываем настройки качества
        setShowSourceSettings(false); // Закрываем настройки озвучки
        setShowSettings(true); // Возвращаемся к основному меню настроек
    };

    // Управление воспроизведением (перемотка назад, вперед, воспроизведение/пауза и т.д.)
    const rewindVideo = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 10);
        }
    };

    const fastForwardVideo = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.min(videoRef.current.duration || 0, videoRef.current.currentTime + 10);
        }
    };

    const toggleFullScreen = () => {
        if (!playerRef.current) return; // Add this check to ensure playerRef is not null
    
        if (!isFullScreen) {
            if (playerRef.current.requestFullscreen) {
                playerRef.current.requestFullscreen();
            } else if (playerRef.current.mozRequestFullScreen) {
                playerRef.current.mozRequestFullScreen();
            } else if (playerRef.current.webkitRequestFullscreen) {
                playerRef.current.webkitRequestFullscreen();
            } else if (playerRef.current.msRequestFullscreen) {
                playerRef.current.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    const handleFullScreenChange = () => {
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
        setIsFullScreen(!!fullscreenElement);
    };

    const handleMouseMove = () => {
        setShowControls(true);
        if (hideControlsTimeout.current) {
            clearTimeout(hideControlsTimeout.current);
        }
        hideControlsTimeout.current = setTimeout(() => {
            setShowControls(false);
        }, 5000);
    };

    const togglePlayPause = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            if (videoElement.paused) {
                videoElement.play().catch(error => {
                    console.error("Ошибка при попытке воспроизвести:", error);
                });
                setIsPlaying(true);
            } else {
                videoElement.pause();
                setIsPlaying(false);
            }
        }
    }

    const toggleMute = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.muted = !isMuted;
            setIsMuted(prev => !prev);
        }
    };

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);
        if (videoRef.current) {
            videoRef.current.volume = newVolume;
        }
    };

    const handleKeyPress = (e) => {
        if (e.code === 'Space') {
            e.preventDefault();
            togglePlayPause();
        } else if (e.code === 'KeyF') {
            e.preventDefault();
            toggleFullScreen();
        } else if (e.code === 'ArrowLeft') {
            e.preventDefault();
            rewindVideo(); // Перемотка назад на 10 секунд
        } else if (e.code === 'ArrowRight') {
            e.preventDefault();
            fastForwardVideo(); // Перемотка вперед на 10 секунд
        }
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const updateTimeAndBuffer = () => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
            const ranges = [];
            for (let i = 0; i < videoRef.current.buffered.length; i++) {
                ranges.push({
                    start: videoRef.current.buffered.start(i),
                    end: videoRef.current.buffered.end(i),
                });
            }
            setBufferedRanges(ranges);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(updateTimeAndBuffer, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Обработка полноэкранного режима и клавиатурных событий
    useEffect(() => {
        const videoElement = videoRef.current;

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('MSFullscreenChange', handleFullScreenChange);
        document.addEventListener('keydown', handleKeyPress);

        const handleEnded = () => {
            videoElement.pause();
            setIsPlaying(false);
        };

        const handleWaiting = () => {
            setIsLoading(true);
        };

        const handleCanPlay = () => {
            setIsLoading(false);
        };

        const handleTimeUpdate = () => {
            if (videoElement && videoElement.currentTime <= videoElement.duration) {
                setCurrentTime(videoElement.currentTime);
            }
        };

        if (videoElement) {
            videoElement.addEventListener('ended', handleEnded);
            videoElement.addEventListener('waiting', handleWaiting); // При буферизации
            videoElement.addEventListener('canplay', handleCanPlay); // При готовности к воспроизведению
            videoElement.addEventListener('timeupdate', handleTimeUpdate);
        }

        handleMouseMove();

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
            document.removeEventListener('keydown', handleKeyPress);

            if (videoElement) {
                videoElement.removeEventListener('ended', handleEnded);
                videoElement.removeEventListener('waiting', handleWaiting);
                videoElement.removeEventListener('canplay', handleCanPlay);
                videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, []);

    // Получение данных аниме с API
    useEffect(() => {
        const fetchAnimeData = debounce(async () => {
            setIsLoading(true);
            setErrorMessage(null); // Reset error message before loading new data
        
            if (!animeTitle) {
                console.error("Название аниме не предоставлено");
                setIsLoading(false);
                setErrorMessage("Название аниме не предоставлено.");
                return;
            }
        
            try {
                console.log(`Запрос к API: title=${animeTitle}, episode=${selectedEpisode}, source=${source}`);
                const response = await fetch(`https://player.aniplay.fun/search?title=${encodeURIComponent(animeTitle)}&episode=${selectedEpisode}&source=${encodeURIComponent(source)}`);
                const data = await response.json();
                console.log("Полученные данные:", data);
        
                if (data.error) {
                    console.error("Ошибка при загрузке данных:", data.error);
                    setErrorMessage("Ошибка при загрузке данных.");
                } else if (data.videos && Object.keys(data.videos).length > 0) {
                    // Sort resolutions to get the highest quality available
                    const availableQualities = Object.keys(data.videos).sort((a, b) => parseInt(b) - parseInt(a));
                    const highestQuality = availableQualities[0];
        
                    setAnimeData(data);
                    setAvailableResolutions(availableQualities);
                    setCurrentQuality(highestQuality);
        
                    if (data.uhd === "true") {
                        document.title = `AniPlay | ${animeTitle} в 4К`;
                    } else {
                        document.title = `AniPlay | ${animeTitle}`;
                    }
        
                    console.log("Доступные разрешения:", availableQualities);
                    console.log("Выбранное разрешение:", highestQuality);
                } else {
                    console.error("Видео не найдено для указанных параметров.");
                    setErrorMessage("Видео не найдено для указанных параметров.");
                    document.title = `AniPlay | ${animeTitle}`;
                }
            } catch (error) {
                console.error("Ошибка сети или сервера:", error);
                setErrorMessage("Ошибка сети или сервера.");
            } finally {
                setIsLoading(false);
            }
        }, 300);
    
        fetchAnimeData();
    
        return () => {
            fetchAnimeData.cancel();
        };
    }, [animeTitle, selectedEpisode, source]);

    // Обновление источника видео при изменении качества
    useEffect(() => {
        if (animeData && animeData.videos && currentQuality) {
            const videoUrl = animeData.videos[currentQuality];
            console.log('Установка источника видео:', videoUrl);
    
            if (videoUrl) {
                const currentTime = videoRef.current.currentTime;
    
                videoRef.current.src = videoUrl;
                videoRef.current.load();
    
                videoRef.current.onloadeddata = () => {
                    if (videoRef.current) {
                        videoRef.current.currentTime = currentTime;
                        videoRef.current.play()
                            .then(() => console.log("Видео воспроизводится"))
                            .catch(error => {
                                console.error("Ошибка при воспроизведении:", error);
                                setErrorMessage("Ошибка при воспроизведении видео.");
                            });
                    }
                };
    
                videoRef.current.onerror = (e) => {
                    console.error("Ошибка загрузки видео:", videoUrl, e);
                    setErrorMessage("Не удалось загрузить видео. Попробуйте другой источник или качество.");
                };
            }
        }
    }, [animeData, currentQuality]);
    
    

    // Изменение разрешения видео
    const changeResolution = (resolution) => {
        if (!animeData || !selectedEpisode) return;

        const videoUrl = animeData.videos[resolution];
        if (!videoUrl) {
            console.error("Не найдено видео для выбранного качества:", resolution);
            return;
        }

        const currentTime = videoRef.current.currentTime;
        videoRef.current.src = videoUrl;
        videoRef.current.load();
        videoRef.current.addEventListener('loadeddata', () => {
            videoRef.current.currentTime = currentTime;
            videoRef.current.play().catch(error => console.error("Ошибка при воспроизведении:", error));
        }, { once: true });

        setCurrentQuality(resolution);
    };

    // Управление выбором серии
    const toggleEpisodes = () => {
        setShowEpisodes((prev) => !prev);
    };

    const handleEpisodeSelection = (episodeNumber) => {
        setSelectedEpisode(episodeNumber.toString());
    };

    // Обработка кликов вне настроек и списка серий
    useEffect(() => {
        const handleClickOutsideSettings = (event) => {
            if (
                settingsRef.current &&
                !settingsRef.current.contains(event.target) &&
                settingsIconRef.current &&
                !settingsIconRef.current.contains(event.target)
            ) {
                setShowSettings(false);
            }
        };

        const handleClickOutsideEpisodes = (event) => {
            if (
                listEpisodeRef.current &&
                !listEpisodeRef.current.contains(event.target) &&
                episodeToggleIconRef.current &&
                !episodeToggleIconRef.current.contains(event.target)
            ) {
                setShowEpisodes(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideSettings);
        document.addEventListener('mousedown', handleClickOutsideEpisodes);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSettings);
            document.removeEventListener('mousedown', handleClickOutsideEpisodes);
        };
    }, []);

    // Форматирование описаний разрешений
    const getResolutionDescription = (resolution) => {
        switch (resolution) {
            case '2160p':
                return (
                    <>
                    <div>4k</div>
                    <div>Сверхвысокое разрешение</div>
                    </>
                );
            case '720p':
                return (
                    <>
                    <div>hd</div>
                    <div>Высокое разрешение</div>
                    </>
                );
            case '480p':
                return 'Среднее разрешение';
            case '360p':
                return 'Низкое разрешение';
            default:
                return 'Неизвестное разрешение';
        }
    };;

    // Форматирование описаний озвучки
    const getResolutionDescriptionSource = (resolution) => {
        switch (resolution) {
            case 'AniLibria':
                return (
                    <div>Так звучит аниме!</div>
                );
            case 'JAM CLUB':
                return (
                    <>
                    <div>Голос вашего аниме</div>
                    </>
                );
            case 'AniDUB':
                return (
                    <>
                    <div>Мы делаем аниме лучше!</div>
                    </>
                );
            case 'SHIZA Project':
                return (
                    <>
                    <div>Там, где рождается аниме...</div>
                    </>
                );
            case 'AnimeVost':
                return (
                    <>
                    <div>Быстрая озвучка аниме</div>
                    </>
                );
            case 'Dream Cast':
                return (
                    <>
                    <div>Твои любимые герои оживают в наших голосах</div>
                    </>
                );
            default:
                return 'Простите не придумал';
        }
    };;


    // Обработчик выбора озвучки
    const handleSelectVoiceActing = (voiceActing) => {
        // Обновляем параметр 'source' в URL
        const newSearchParams = new URLSearchParams(search);
        newSearchParams.set('source', voiceActing);
        navigate({
            pathname: pathname,
            search: newSearchParams.toString(),
        });
        // Закрываем настройки озвучки после выбора
        setShowSourceSettings(false);
        setShowSettings(false);
        // Состояние `source` будет обновлено через useEffect
    };
    

    useEffect(() => {
        const newQueryParams = new URLSearchParams(search);
        const newSource = newQueryParams.get('source') || 'AniLibria';
        setSource(newSource);
    }, [search]);



      const getQualityLabel = (quality) => {
            if (!quality) return null;
        
            if (quality === '2160p') {
                return <div className='icons_to_4k'>4K</div>;
            } else if (quality === '720p') {
                return <div className='icons_to_4k'>HD</div>;
            }
        };

    // Пример функции для проверки авторизации
    const checkAuth = () => {
        const token = localStorage.getItem('token'); // Пример
        setIsAuthenticated(!!token);
        setIsLoading(false); // Устанавливаем состояние загрузки в false после проверки
    };

    // Вызов функции проверки авторизации при монтировании компонента
    useEffect(() => {
        checkAuth();
    }, []);

    // Если пользователь не авторизован, перенаправьте его на страницу авторизации
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate('/auth');
        }
    }, [isAuthenticated, isLoading, navigate]);

    // Загрузка и обработка ошибок
    if (isLoading || !animeData) {
        if (errorMessage) {
            return (
                <div className='error_404'>
                    <div className='line_top_error_404'>
                    <img src='../icons/logo_no_fon.png'></img>
                    <p>Аниме не найденно или вы неправильно указали адрес страницы!</p>
                    </div>
                    <div className='line_center_error_404'>
                    <img src='../icons/eror_image.gif' style={{width: '200px'}}></img>
                    <a href='/welcomw'>Главная</a>
                    </div>
                    <div className='line_bottom_error_404'>
                    <a href=''>Не терпи и напиши нам в техническую поддержку</a>
                    </div>
                </div>
            );
        } else if (isLoading || !animeData) {
            return (
                <div className="kp-loader">
                    <div className="kp-loader-circle"></div>
                </div>
            );
        }
    }

    return (
        <div
            ref={playerRef}
            className={`player_container ${isFullScreen ? 'full-screen' : ''} ${showControls ? 'controls-visible' : 'controls-hidden'} ${!isPlaying ? 'hidden-when-paused' : ''} ${!showEpisodes ? '' : 'hidden-when-episode'}`}
            onMouseMove={handleMouseMove}
        >
            <video
                ref={videoRef}
                autoPlay
                controls={false}
                style={{ width: '100%', height: '100%' }}
                onClick={togglePlayPause} // Toggle play/pause when clicked
            />
            <div className='player_container_button'>
                <div className='player_container_button_div' onClick={rewindVideo} style={{ cursor: 'pointer' }}>
                    {/* SVG для перемотки назад */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="30" height="30" fill="#ececec"><path d="M480-80q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-440h80q0 117 81.5 198.5T480-160q117 0 198.5-81.5T760-440q0-117-81.5-198.5T480-720h-6l62 62-56 58-160-160 160-160 56 58-62 62h6q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-440q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-80ZM360-320v-180h-60v-60h120v240h-60Zm140 0q-17 0-28.5-11.5T460-360v-160q0-17 11.5-28.5T500-560h80q17 0 28.5 11.5T620-520v160q0 17-11.5 28.5T580-320h-80Zm20-60h40v-120h-40v120Z"></path></svg>
                </div>
                <div onClick={togglePlayPause} style={{ cursor: 'pointer' }}>
                    {isLoading ? (
                        <div className="loader"></div> 
                    ) : isPlaying ? (
                        /* SVG для паузы */
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ececec" width="40" height="40" viewBox="0 0 32 32" version="1.1">
                            <path d="M5.92 24.096q0 0.832 0.576 1.408t1.44 0.608h4.032q0.832 0 1.44-0.608t0.576-1.408v-16.16q0-0.832-0.576-1.44t-1.44-0.576h-4.032q-0.832 0-1.44 0.576t-0.576 1.44v16.16zM18.016 24.096q0 0.832 0.608 1.408t1.408 0.608h4.032q0.832 0 1.44-0.608t0.576-1.408v-16.16q0-0.832-0.576-1.44t-1.44-0.576h-4.032q-0.832 0-1.408 0.576t-0.608 1.44v16.16z"/>
                        </svg>
                    ) : (
                        /* SVG для воспроизведения */
                        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 24 24" fill="none">
                            <path d="M8.286 3.407A1.5 1.5 0 0 0 6 4.684v14.632a1.5 1.5 0 0 0 2.286 1.277l11.888-7.316a1.5 1.5 0 0 0 0-2.555L8.286 3.407z" fill="#ececec"/>
                        </svg>
                    )}
                </div>
                <div className='player_container_button_div' onClick={fastForwardVideo} style={{ cursor: 'pointer' }}>
                    {/* SVG для перемотки вперед */}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="30" height="30" fill="#ececec"><path d="M360-320v-180h-60v-60h120v240h-60Zm140 0q-17 0-28.5-11.5T460-360v-160q0-17 11.5-28.5T500-560h80q17 0 28.5 11.5T620-520v160q0 17-11.5 28.5T580-320h-80Zm20-60h40v-120h-40v120ZM480-80q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-440q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-800h6l-62-62 56-58 160 160-160 160-56-58 62-62h-6q-117 0-198.5 81.5T200-440q0 117 81.5 198.5T480-160q117 0 198.5-81.5T760-440h80q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-80Z"></path></svg>
                </div>
            </div>
            <div className='video_container_header_shadow'></div>
            <div className='player_container_space_top'>
                <div className='player_container_space_top_back'>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="#ececec" 
                        version="1.1" 
                        width="20px" 
                        height="20px"
                        viewBox='0 0 42 42'
                    >
                        <polygon fillRule="evenodd" points="31,38.32 13.391,21 31,3.68 28.279,1 8,21.01 28.279,41 " />
                    </svg>
                    <span>Вернуться назад</span>
                </div>
            </div>
            <div className='player_container_space_top_logo'>
                {/* Рендерить только если title_poster существует */}
                {animeData.title_poster && <img src={animeData.title_poster} alt="Anime Poster"/>}
                <span>{selectedEpisode} серия. {animeData.title}.</span>
            </div>
            <div className='video_container_footer_shadow'></div>
            <div className={`list_catalog_episode ${showEpisodes ? 'show' : ''}`} ref={listEpisodeRef}>
                {/* <div className='top_container_player'>
                    <input  placeholder='Поиск по номеру '/>
                    <div className='filter_episode'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffff" width="20" height="20" viewBox="0 0 24 24">
                            <path d="M22,4.5a1,1,0,0,1-1,1H12a1,1,0,0,1,0-2h9A1,1,0,0,1,22,4.5Zm-1,4H14a1,1,0,0,0,0,2h7a1,1,0,0,0,0-2Zm0,5H16a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Zm0,5H18a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2ZM6,2A1,1,0,0,0,5,3V18.586L3.707,17.293a1,1,0,0,0-1.414,1.414l3,3a1,1,0,0,0,1.416,0l3-3a1,1,0,0,0-1.414-1.414L7,18.586V3A1,1,0,0,0,6,2Z"/>
                        </svg>
                    </div>
                </div> */}
                <div className="left_button_player" onClick={() => scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' })}>
                    {/* SVG для левой кнопки */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
                        <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="white"></path>
                    </svg>
                </div>
                <div className="right_button_player" onClick={() => scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' })}>
                    {/* SVG для правой кнопки */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
                        <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="white"></path>
                    </svg>
                </div>
                <div className='list_episode' ref={scrollContainerRef}>
                    {animeData && animeData.videos && animeData.total_episodes ? (
                        Array.from({ length: animeData.total_episodes }, (_, i) => {
                            const episodeNumber = i + 1;

                            return (
                                <div 
                                    key={episodeNumber} 
                                    className='catalog_item_episode' 
                                    onClick={() => handleEpisodeSelection(episodeNumber)}
                                >
                                    <div className='item_episode'>
                                        <div className="poster_overlay_episode"></div>
                                        {/* Используйте placeholder или добавьте поле для изображения в API */}
                                        <img src='../icons/item.jpg' alt={`Poster for Episode ${episodeNumber}`} />
                                        <div className="item_name_episode">
                                            Серия {episodeNumber}
                                        </div>
                                        <div className="overlay"></div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="error-message">
                            Не удалось загрузить список серий.
                        </div>
                    )}
                </div>
            </div>
            <div ref={settingsRef} className={`settings_video ${showSettings ? 'show' : ''}`} style={{ display: showSettings ? 'flex' : 'none' }}>
                <div className='settings_video_button'>
                    {/* SVG и текст для скорости воспроизведения */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2454 14C15.3433 12.6304 16 10.8919 16 9C16 4.58172 12.4183 1 8 1C3.58172 1 0 4.58172 0 9C0 10.8919 0.656718 12.6304 1.75463 14H6.79822L3.26572 10.1788L4.73432 8.82116L9.5219 14H14.2454ZM9 4C9 4.55228 8.55229 5 8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3C8.55229 3 9 3.44772 9 4ZM4.5 6.5C5.05228 6.5 5.5 6.05228 5.5 5.5C5.5 4.94772 5.05228 4.5 4.5 4.5C3.94772 4.5 3.5 4.94772 3.5 5.5C3.5 6.05228 3.94772 6.5 4.5 6.5ZM14 9C14 9.55229 13.5523 10 13 10C12.4477 10 12 9.55229 12 9C12 8.44772 12.4477 8 13 8C13.5523 8 14 8.44772 14 9ZM12.5 5.5C12.5 6.05228 12.0523 6.5 11.5 6.5C10.9477 6.5 10.5 6.05228 10.5 5.5C10.5 4.94772 10.9477 4.5 11.5 4.5C12.0523 4.5 12.5 4.94772 12.5 5.5Z" fill="#ececec"/>
                    </svg>
                    <span>Скорость воспроизведения</span>
                </div>
                <div className='settings_video_button' onClick={handleShowQualitySettings}>
                    {/* SVG и текст для выбора качества */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" version="1.1">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Dribbble-Light-Preview" transform="translate(-420.000000, -3999.000000)" fill="#ececec">
                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                    <path d="M367,3845 L367,3853 L369,3853 L369,3850 L371,3850 L371,3853 L373,3853 L373,3845 L371,3845 L371,3848 L369,3848 L369,3845 L367,3845 Z M379,3847.5 C379,3847.224 378.776,3847 378.5,3847 L377,3847 L377,3851 L378.5,3851 C378.775,3851 379,3850.775 379,3850.5 L379,3847.5 Z M379.395,3853 L375,3853 L375,3845 L379.395,3845 C380.282,3845 381,3845.719 381,3846.605 L381,3851.395 C381,3852.281 380.282,3853 379.395,3853 L379.395,3853 Z M364,3839 L364,3843 L366,3843 L366,3841 L382,3841 L382,3843 L384,3843 L384,3839 L364,3839 Z M382,3855 L384,3855 L384,3859 L364,3859 L364,3855 L366,3855 L366,3857 L382,3857 L382,3855 Z" id="HD-[#941]"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Качество {currentQuality}</span>
                </div>
                <div className='settings_video_button' onClick={handleShowSourceSettings}>
                    {/* SVG и текст для выбора озвучки */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0H6V2H10V4H8.86807C8.57073 5.66996 7.78574 7.17117 6.6656 8.35112C7.46567 8.73941 8.35737 8.96842 9.29948 8.99697L10.2735 6H12.7265L15.9765 16H13.8735L13.2235 14H9.77647L9.12647 16H7.0235L8.66176 10.9592C7.32639 10.8285 6.08165 10.3888 4.99999 9.71246C3.69496 10.5284 2.15255 11 0.5 11H0V9H0.5C1.5161 9 2.47775 8.76685 3.33437 8.35112C2.68381 7.66582 2.14629 6.87215 1.75171 6H4.02179C4.30023 6.43491 4.62904 6.83446 4.99999 7.19044C5.88743 6.33881 6.53369 5.23777 6.82607 4H0V2H4V0ZM12.5735 12L11.5 8.69688L10.4265 12H12.5735Z" fill="#ececec"/>
                    </svg>
                    <span>Озвучка {source}</span>
                </div>
            </div>
            {/* Настройки качества */}
            <div
                ref={qualitySettingsRef}
                className='settings_quality'
                style={{ display: showQualitySettings ? 'flex' : 'none' }}
            >
                <div className='settings_quality_button_back settings_quality_button' onClick={handleBackToSettings}>
                    {/* SVG и текст для возврата */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 1024 1024">
                        <path fill="#ececec" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
                        <path fill="#ececec" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
                    </svg>
                    Назад
                </div>
                {availableResolutions.length > 0 ? (
                    [...availableResolutions].sort((a, b) => parseInt(b) - parseInt(a)).map((resolution) => (
                        <div
                            className='settings_quality_button settings_quality_button_quality'
                            key={resolution}
                            onClick={() => changeResolution(resolution)}
                        >
                            <p>{resolution}</p>
                            <div className='desript_settings_quality_button_quality'>{getResolutionDescription(resolution)}</div>
                        </div>
                    ))
                ) : (
                    <div>Нет доступных разрешений</div>
                )}
            </div>
            {/* Настройки озвучки */}
            <div
                ref={sourceSettingsRef}
                className='settings_source'
                style={{ display: showSourceSettings ? 'flex' : 'none' }}
            >
                <div className='settings_source_button_back settings_source_button' onClick={handleBackToSettings}>
                    {/* SVG и текст для возврата */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 1024 1024">
                        <path fill="#ececec" d="M224 480h640a32 32,0,1,1,0,64H224a32 32,0,0,1,0-64z"/>
                        <path fill="#ececec" d="m237.248 512 265.408 265.344a32 32,0,0,1-45.312 45.312l-288-288a32 32,0,0,1,0-45.312l288-288a32 32,0,1,1,45.312 45.312L237.248 512z"/>
                    </svg>
                    Назад
                </div>
                {animeData && animeData.available_voice_actings && animeData.available_voice_actings.length > 0 ? (
                    animeData.available_voice_actings.map((voiceActing) => (
                        <div
                            className='settings_source_button settings_source_button_source'
                            key={voiceActing}
                            onClick={() => handleSelectVoiceActing(voiceActing)}
                            style={{ cursor: 'pointer' }}
                        >
                            <p>{voiceActing}</p>
                            <div className='descript_settings_source_button'>{getResolutionDescriptionSource(voiceActing)}</div>
                        </div>
                    ))
                ) : (
                    <div>Нет доступных озвучек</div>
                )}
            </div>

            <div className='player_container_footer'>
                <div className='top_player_container_footer'>
                    <div className='player_container_footer_timeline_hover_cont'
                        onMouseMove={(e) => {
                            const timelineWidth = e.currentTarget.clientWidth;
                            const offsetX = e.clientX - e.currentTarget.getBoundingClientRect().left;
                            let percentage = offsetX / timelineWidth;
                            if (percentage < 0) percentage = 0;
                            if (percentage > 1) percentage = 1;
                            const time = percentage * (videoRef.current?.duration || 0);

                            setTooltipTime(time);
                            setIsTooltipVisible(true);
                        }}
                        onMouseLeave={() => setIsTooltipVisible(false)}
                    >
                        {bufferedRanges.map((range, index) => (
                            <div
                                key={index}
                                className='buffered'
                                style={{
                                    position: 'absolute',
                                    left: `${(range.start / (videoRef.current?.duration || 1)) * 100}%`,
                                    width: `${((range.end - range.start) / (videoRef.current?.duration || 1)) * 100}%`,
                                    height: '100%',
                                    background: 'none',
                                }}
                            />
                        ))}
                        <div
                            style={{
                                width: `${(currentTime / (videoRef.current?.duration || 1)) * 100}%`,
                                position: 'absolute',
                                height: '100%',
                                background: '#ffff',
                                borderRadius: "2px",
                            }}
                        />
                        <input
                            type="range"
                            min="0"
                            max={videoRef.current?.duration || 0}
                            value={currentTime || 0}
                            step="0.1" // Задайте шаг для плавного передвижения
                            onChange={(e) => {
                                const newTime = parseFloat(e.target.value);
                                if (videoRef.current) {
                                    videoRef.current.currentTime = newTime;
                                    setCurrentTime(newTime);
                                }
                            }}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                cursor: 'pointer',
                                appearance: 'none',
                                background: 'none',
                                zIndex: 2,
                                borderRadius: "2px",
                            }}
                        />
                        {isTooltipVisible && tooltipTime != null && videoRef.current?.duration ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    left: `${(tooltipTime / videoRef.current.duration) * 100}%`,
                                    bottom: '30px',
                                    background: 'rgba(88, 88, 88, .35)',
                                    color: '#ccc',
                                    padding: '5px 10px',
                                    borderRadius: '50px',
                                    fontSize: '12px',
                                    transform: 'translateX(-50%)',
                                }}
                            >
                                {formatTime(tooltipTime)} {/* Показываем текущий тайм-код */}
                            </div>
                        ) : null}
                    </div>
                    <div className='time_time'>
                        {formatTime(currentTime)} / {formatTime(videoRef.current?.duration)}
                    </div>
                </div>
                <div className='player_container_footer_1'>
                    <div 
                        className='player_container_footer_space1'
                        onMouseEnter={handleMouseEnter} 
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className='player_container_footer_space1_add_episode' onClick={toggleEpisodes} ref={episodeToggleIconRef}>
                            {/* SVG и текст для выбора серии */}
                            <svg width="20" height="20" viewBox="0 0 234 234" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="26" y="10" width="183" height="44" fill="#ececec"/>
                                <rect y="95" width="234" height="120" fill="#ececec"/>
                            </svg>
                            {/* <span>{showEpisodes ? 'Скрыть серии' : 'Выбор серии'}</span> */}
                        </div>
                        <div className='player_container_footer_space1_skip_episode'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path d="M7.788 17.444A.5.5 0 0 1 7 17.035V6.965a.5.5 0 0 1 .788-.409l7.133 5.036a.5.5 0 0 1 0 .816l-7.133 5.036zM16 7a1 1 0 0 1 2 0v10a1 1 0 1 1-2 0V7z" fill='#ececec'/>
                                </g>
                            </svg>
                        </div>
                        <div className='player_container_footer_icon pointer_folume' style={{ width: "40px", minWidth: "40px", maxWidth: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: 'center' }} 
                            onClick={toggleMute}>
                            {isMuted ? (
                                /* SVG для выключенного звука */
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512" version="1.1">
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="icon" fill="#ececec" transform="translate(42.666667, 59.581722)">
                                            <path d="M47.0849493,-1.42108547e-14 L298.668,251.583611 L304.101001,257.015597 L304.101,257.016 L353.573532,306.488791 C353.573732,306.488458 353.573933,306.488124 353.574133,306.48779 L384.435257,337.348961 L384.434,337.349 L409.751616,362.666662 L379.581717,392.836561 L191.749,205.003 L191.749973,369.105851 L81.0208,283.647505 L7.10542736e-15,283.647505 L7.10542736e-15,112.980838 L80.8957867,112.980838 L91.433,104.688 L16.9150553,30.169894 L47.0849493,-1.42108547e-14 Z M361.298133,28.0146513 C429.037729,103.653701 443.797162,209.394226 405.578884,298.151284 L372.628394,265.201173 C396.498256,194.197542 381.626623,113.228555 328.013013,54.642278 L361.298133,28.0146513 Z M276.912853,95.5237713 C305.539387,127.448193 318.4688,168.293162 315.701304,208.275874 L266.464558,159.040303 C261.641821,146.125608 254.316511,133.919279 244.488548,123.156461 L243.588693,122.182545 L276.912853,95.5237713 Z M191.749973,25.7516113 L191.749,84.3256113 L158.969,51.5456113 L191.749973,25.7516113 Z" id="Combined-Shape"></path>
                                        </g>
                                    </g>
                                </svg>
                            ) : (
                                /* SVG для включенного звука */
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512" version="1.1">
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="icon" fill="#ececec" transform="translate(42.666667, 85.333333)">
                                            <path d="M361.299413,341.610667 L328.014293,314.98176 C402.206933,233.906133 402.206933,109.96608 328.013013,28.8906667 L361.298133,2.26304 C447.910187,98.97536 447.908907,244.898347 361.299413,341.610667 Z M276.912853,69.77216 L243.588693,96.4309333 C283.38432,138.998613 283.38304,204.87488 243.589973,247.44256 L276.914133,274.101333 C329.118507,215.880107 329.118507,127.992107 276.912853,69.77216 Z M191.749973,1.42108547e-14 L80.8957867,87.2292267 L7.10542736e-15,87.2292267 L7.10542736e-15,257.895893 L81.0208,257.895893 L191.749973,343.35424 L191.749973,1.42108547e-14 L191.749973,1.42108547e-14 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </svg>
                            )}
                        </div>
                        <div className={`player_container_footer_icon_folume ${isHovering ? 'visible' : 'hidden'}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <input 
                                type="range" 
                                min="0" 
                                max="1" 
                                step="0.01" 
                                value={volume} 
                                onChange={handleVolumeChange} 
                                style={{ marginLeft: '-10px', width: '70px' }} 
                            />
                        </div>
                    </div>
                    <div className='player_container_footer_space2'>
                        <div 
                            ref={settingsIconRef} 
                            className='player_container_footer_icon' 
                            onClick={() => setShowSettings(prev => !prev)} 
                            style={{ width: "40px", maxWidth: "40px", minWidth: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: 'center' }}
                        >
                            {/* SVG для иконки настроек */}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25" fill="#ececec">
                                <rect fill="none" height="24" width="24"></rect>
                                <path d="M19.5,12c0-0.23-0.01-0.45-0.03-0.68l1.86-1.41c0.4-0.3,0.51-0.86,0.26-1.3l-1.87-3.23c-0.25-0.44-0.79-0.62-1.25-0.42 l-2.15,0.91c-0.37-0.26-0.76-0.49-1.17-0.68l-0.29-2.31C14.8,2.38,14.37,2,13.87,2h-3.73C9.63,2,9.2,2.38,9.14,2.88L8.85,5.19 c-0.41,0.19-0.8,0.42-1.17,0.68L5.53,4.96c-0.46-0.2-1-0.02-1.25,0.42L2.41,8.62c-0.25,0.44-0.14,0.99,0.26,1.3l1.86,1.41 C4.51,11.55,4.5,11.77,4.5,12s0.01,0.45,0.03,0.68l-1.86,1.41c-0.4,0.3-0.51,0.86-0.26,1.3l1.87,3.23c0.25,0.44,0.79,0.62,1.25,0.42 l2.15-0.91c0.37,0.26,0.76,0.49,1.17,0.68l0.29,2.31C9.2,21.62,9.63,22,10.13,22h3.73c0.5,0,0.93-0.38,0.99-0.88l0.29-2.31 c0.41-0.19,0.8-0.42,1.17-0.68l2.15,0.91c0.46,0.2,1,0.02,1.25-0.42l1.87-3.23c0.25-0.44,0.14-0.99-0.26-1.3l-1.86-1.41 C19.49,12.45,19.5,12.23,19.5,12z M12.04,15.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.97,15.5,12.04,15.5z"></path>
                            </svg>

                            
                            {getQualityLabel(currentQuality)}
                            
                        </div>
                        <div className='player_container_footer_icon'>
                            {/* Дополнительная иконка, если необходима */}
                            <svg height="40" width="40" version="1.1" viewBox="0 0 36 36">
                                <path d="M25,17 L17,17 L17,23 L25,23 L25,17 L25,17 Z M29,25 L29,10.98 C29,9.88 28.1,9 27,9 L9,9 C7.9,9 7,9.88 7,10.98 L7,25 C7,26.1 7.9,27 9,27 L27,27 C28.1,27 29,26.1 29,25 L29,25 Z M27,25.02 L9,25.02 L9,10.97 L27,10.97 L27,25.02 L27,25.02 Z" fill="#ececec"></path>
                            </svg>
                        </div>
                        <div 
                            className='player_container_footer_icon fullscreen-icon' 
                            onClick={toggleFullScreen}
                            role="button"
                            tabIndex="0"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    e.preventDefault();
                                    toggleFullScreen();
                                }
                            }}
                        >
                            {isFullScreen ? (
                                /* SVG для выхода из полноэкранного режима */
                                <svg height="40" width="40" version="1.1" viewBox="0 0 36 36" fill="#ececec">
                                    <g>
                                        <path d="m 14,14 -4,0 0,2 6,0 0,-6 -2,0 0,4 0,0 z"></path>
                                    </g>
                                    <g>
                                        <path d="m 22,14 0,-4 -2,0 0,6 6,0 0,-2 -4,0 0,-4 0,0 z"></path>
                                    </g>
                                    <g>
                                        <path d="m 20,26 2,0 0,-4 4,0 0,-2 -6,0 0,6 0,0 z"></path>
                                    </g>
                                    <g>
                                        <path d="m 10,22 4,0 0,4 2,0 0,-6 -6,0 0,2 0,0 z"></path>
                                    </g>
                                </svg>
                            ) : (
                                /* SVG для входа в полноэкранный режим */
                                <svg height="40" width="40" version="1.1" viewBox="0 0 36 36" fill="#ececec">
                                    <g className="ytp-fullscreen-button-corner-0">
                                        <path d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z"></path>
                                    </g>
                                    <g className="ytp-fullscreen-button-corner-1">
                                        <path d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z"></path>
                                    </g>
                                    <g className="ytp-fullscreen-button-corner-2">
                                        <path d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z"></path>
                                    </g>
                                    <g className="ytp-fullscreen-button-corner-3">
                                        <path d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z"></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

