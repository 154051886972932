import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../components/Footer';

const statusTranslations = {
  released: 'Вышел',
  ongoing: 'Онгоинг',
  latest: 'Последний',
  anons: 'Анонсирован'
};

const kindTranslationsInfo = {
  tv: 'Сериале',
  movie: 'Фильме',
  ova: 'OVA',
  ona: 'ONA',
  special: 'Спецвыпуске',
  tv_special: 'TV Спецвыпуске',
};

const kindTranslations = {
  tv: 'TV Сериал',
  movie: 'Фильм',
  ova: 'OVA',
  ona: 'ONA',
  special: 'Спецвыпуск',
  tv_special: 'TV Спецвыпуск',
};

export default function PlayEpisode({ anime, userId  }) {
  const episodeListRef = useRef(null);
  const episodeListRef2 = useRef(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [viewerImageUrl, setViewerImageUrl] = useState('');
  const [openingUrl, setOpeningUrl] = useState(); // Добавлено состояние
  const [isFetchedFromServer, setIsFetchedFromServer] = useState(false); // Новое состояние
  const [kinopoiskUrl, setKinopoiskUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const animeId = anime.id;
  
  const [apiData, setApiData] = useState(null); // Новое состояние для данных API
  const [hasError, setHasError] = useState(false); // Состояние для проверки ошибок

  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);
  const imageRef = useRef(null);

  const [isMuted, setIsMuted] = useState(true);
  const [volume, setVolume] = useState(100); // Начальный уровень громкости

  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);

  const [isRemembered, setIsRemembered] = useState(false);
  const [isViewed, setIsViewed] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [isVisible, setIsVisible] = useState(false);

  const showMessage = (messageType, message) => {
    if (messageType === 'error') setErrorMessage(message);
    else setSuccessMessage(message);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 5000);
  };

  const checkAnimeInCollection = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`https://api.aniplay.fun/api/collection`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const collection = response.data;

        console.log("Collection data:", collection); // Отладочная информация
        console.log("Checking for anime id:", anime.id); // Отладочная информация

        const remembered = collection.some(item => Number(item.anime_id) === Number(anime.id) && item.collection_type === 'remembered');
        const viewed = collection.some(item => Number(item.anime_id) === Number(anime.id) && item.collection_type === 'viewed');

        console.log("Remembered:", remembered); // Отладочная информация
        console.log("Viewed:", viewed); // Отладочная информация

        setIsRemembered(remembered);
        setIsViewed(viewed);
    } catch (error) {
        console.error('Ошибка при получении коллекции:', error);
        setErrorMessage('Ошибка при получении коллекции.');
    }
};

  const handleAddToRemembered = async () => {
    if (isRemembered) {
        showMessage('error', 'Аниме уже добавлено в "Запомненные"!');
        return;
    }
    try {
        const token = localStorage.getItem('token');
        await axios.post('https://api.aniplay.fun/api/collection', {
            user_id: userId,
            anime_id: anime.id,
            collection_type: 'remembered'
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setIsRemembered(true);
        showMessage('success', 'Аниме успешно добавлено в "Запомненные"!');
    } catch (error) {
        showMessage('error', 'Ошибка при добавлении аниме в "Запомненные".');
    }
  };

  const handleAddToViewed = async () => {
    if (isViewed) {
        showMessage('error', 'Аниме уже добавлено в "Просмотренные"!');
        return;
    }
    try {
        const token = localStorage.getItem('token');
        await axios.post('https://api.aniplay.fun/api/collection', {
            user_id: userId,
            anime_id: anime.id,
            collection_type: 'viewed'
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setIsViewed(true);
        showMessage('success', 'Аниме успешно добавлено в "Просмотренные"!');
    } catch (error) {
        showMessage('error', 'Ошибка при добавлении аниме в "Просмотренные".');
    }
  };

  useEffect(() => {
    checkAnimeInCollection();
  }, [userId, anime.id]); // Проверяем коллекцию при изменении userId или anime.id


    // Функция для получения комментариев
    const fetchComments = async (animeId) => {
      try {
        const response = await axios.get(`https://api.aniplay.fun/api/comments/${animeId}`);
        return response.data; // Предполагается, что response.data содержит массив комментариев
      } catch (error) {
        console.error('Ошибка получении комментариев:', error);
        throw error;
      }
    };
  
    useEffect(() => {
      const fetchCommentsData = async () => {
        try {
          const commentsData = await fetchComments(anime.id);
          setComments(commentsData);
        } catch (error) {
          console.error('Ошибка при загрузке комментариев:', error);
        }
      };
  
      fetchCommentsData();
    }, [anime.id]);
  
    const handleCommentSubmit = async (e) => {
      e.preventDefault();
      if (!newComment) return;
    
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://api.aniplay.fun/api/comments', {
          anime_id: anime.id,
          comment: newComment
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Создаем новый комментарий на основе ответа сервера
        const newCommentData = {
          id: response.data.id, // Предполагается, что сервер возвращает ID нового комментария
          avatar: response.data.avatar, // Предполагается, что сервер возвращает URL аватара
          login: response.data.login, // Предполагается, что сервер возвращает логин пользователя
          comment: newComment
        };
    
        // Обновляем состояние комментариев
        setComments((prevComments) => [...prevComments, newCommentData]);
        setNewComment('');
    
        // Выводим сообщение об успешном добавлении комментария
        showMessage('success', 'Комментарий успешно добавлен!');
    
      } catch (error) {
        console.error('Error adding comment:', error);
        showMessage('error', 'Ошибка при добавлении комментария.');
      }
    };
    

  const handleSoundToggle = () => {
    setIsMuted(prev => !prev);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted; // Переключаем mute на видео
    }
  };

  useEffect(() => {
    const fetchApiData = async () => {
      setLoading(true); // Начинаем загрузку
      try {
        const response = await fetch(`https://api.aniplay.fun/storage/${anime.id}/`);
        if (response.ok) {
          const data = await response.json();
          setApiData(data);
          setHasError(false);
        } else {
          console.warn(`Anime with id ${anime.id} not found on server.`);
          setHasError(true);
        }
      } catch (error) {
        console.error('Error fetching API data:', error);
        setHasError(true);
      } finally {
        setLoading(false); // Завершаем загрузку
      }
    };

    fetchApiData();
  }, [anime.id]);

  const checkForVideo = () => {
    if (apiData && apiData.trailers) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      checkForVideo();
    }, 5000);

    return () => clearTimeout(timer);
  }, [apiData]);

  const animateTransition = () => {
    if (imageRef.current && videoRef.current) {
      if (showVideo) {
        imageRef.current.style.transition = 'opacity 0.5s ease';
        imageRef.current.style.opacity = 0;

        setTimeout(() => {
          videoRef.current.style.transition = 'opacity 0.5s ease';
          videoRef.current.style.opacity = 1;
          videoRef.current.play(); // Начинаем воспроизведение сразу
        }, 500);
      } else {
        videoRef.current.style.transition = 'opacity 0.5s ease';
        videoRef.current.style.opacity = 0;

        setTimeout(() => {
          imageRef.current.style.transition = 'opacity 0.5s ease';
          imageRef.current.style.opacity = 1;
        }, 500);
      }
    }
  };

  useEffect(() => {
    animateTransition();
  }, [showVideo]);

  useEffect(() => {
    animateTransition();
  }, [showVideo]);



  // Handle the external links dynamically
  const handleExternalLinkClick = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    document.title = `AniPlay | ${anime.russian}`;
    if (anime?.externalLinks) {
      // Фильтрация ссылок, чтобы оставить только те, где kind === 'kinopoisk'
      const kinopoiskLink = anime.externalLinks.find(link => link.kind === 'kinopoisk');
      if (kinopoiskLink) {
        setKinopoiskUrl(kinopoiskLink.url); // Сохранить URL, если ссылка найдена
      }
    }
  }, [anime]);

  // Функции прокрутки (остаются без изменений)
  const scrollLeft = () => {
    if (episodeListRef.current) {
      episodeListRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (episodeListRef.current) {
      episodeListRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const scrollLeft2 = () => {
    if (episodeListRef2.current) {
      episodeListRef2.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight2 = () => {
    if (episodeListRef2.current) {
      episodeListRef2.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const closeViewer = (e) => {
    if (
      e.target.className === 'photo_viewer_container' || 
      e.target.className === 'anime_body'
    ) {
      setIsViewerOpen(false);
    }
  };

  const openViewer = (imageUrl) => {
    setViewerImageUrl(imageUrl);
    setIsViewerOpen(true);
  };

  const cleanDescription = (description) => {
    return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
  };

  const isHentai = anime.genres.some((genre) => genre.russian.toLowerCase() === 'хентай');

  // Функция для форматирования даты
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Получаем текущую дату
    const currentDate = new Date();
    
    // Форматируем дату как день:месяц:год
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    
    // Рассчитываем количество дней до следующей серии
    const timeDifference = date - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Переводим разницу в дни

    // Определяем правильное окончание для слова "день"
    const dayWord = daysLeft === 1 ? 'день' : (daysLeft > 1 && daysLeft < 5) ? 'дня' : 'дней';

    // Формируем строку с датой и количеством дней
    return `${day}.${month}.${year} (через ${daysLeft} ${dayWord})`;
  };

  // useEffect для получения трейлера с сервера
  useEffect(() => {
    const fetchOpening = async () => {
      try {
        const response = await fetch(`https://api.aniplay.fun/anime/${anime.id}`);

        if (response.ok) {
          const data = await response.json();
          if (data.opening) {
            // Предполагается, что 'opening' содержит относительный путь, например '/trailers/57058.webm'
            setOpeningUrl(`https://api.aniplay.fun${data.opening}`);
            setIsFetchedFromServer(true); // Устанавливаем флаг
          }
        } else {
          console.warn(`Anime with id ${anime.id} not found on server.`);
          // Можно оставить default значение или обработать иначе
        }
      } catch (error) {
        console.error('Error fetching opening URL:', error);
        // Можно оставить default значение или обработать иначе
      }
    };

    fetchOpening();
  }, [anime.id]);

  // Найти видео с kind 'op' и извлечь imageUrl
  const opVideo = anime.videos.find(video => video.kind === 'op');
  const opVideoImageUrl = opVideo ? `https:${opVideo.imageUrl}` : '';
  const opVideoName = opVideo ? opVideo.name : 'Название не найдено';
  const opVideoUrl = opVideo ? opVideo.url : '';
  const ratingScore10 = anime.scoresStats.find(stat => stat.score === 10);
  const ratingCount10 = ratingScore10 ? ratingScore10.count : '0';

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
          {score}
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }

  if (loading) {
    return (
      <div className="kp-loader">
        <div className="kp-loader-circle"></div>
      </div>
    );
  }

  return (
    <>
        {isVisible && (errorMessage || successMessage) && (
          <div className={`alert ${errorMessage ? 'error' : 'success'}`}>
            <span className="alert_message success">{errorMessage || successMessage}</span>
          </div>
        )}
        <div className='iframe_anime_body'>
            <div className='back_image_poster'>
              <video
                ref={videoRef}
                style={{ opacity: showVideo ? 1 : 0 }}
                onEnded={() => setShowVideo(false)}
                muted={isMuted} // Устанавливаем начальное состояние звука
                src={apiData ? apiData.trailers : ''}
                preload="auto" // Добавьте этот атрибут
              />
              <img
                ref={imageRef}
                src={apiData ? apiData.sap_default : anime.poster.originalUrl}
                alt="Anime Image"
                style={{ opacity: showVideo ? 0 : 1 }}
              />
              {showVideo && ( // Кнопка звука появляется только когда видео отображается
                <div
                  className="iframe_anime_body_butt_sound"
                  onClick={handleSoundToggle}
                >
                  {isMuted ? (
                    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="PlayerControls_volumeIcon__J2RHB" aria-label="Включить звук">
                      <path fillRule="evenodd" clipRule="evenodd" d="M6 8.5 12.5 4v16L6 15.5H2.5v-7H6Zm10.393 7.493 2.296-2.296 2.296 2.296 1.698-1.697L20.386 12l2.297-2.296-1.698-1.697-2.296 2.296-2.296-2.296-1.697 1.697L16.992 12l-2.296 2.296 1.697 1.697Z" fill="#fff"></path>
                    </svg>
                  ) : (
                    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="PlayerControls_volumeIcon__J2RHB" aria-label="Выключить звук">
                      <path fillRule="evenodd" clipRule="evenodd" d="M24 12a11 11 0 0 1-4.219 8.672l-1.583-1.81A8.605 8.605 0 0 0 21.6 12c0-2.8-1.335-5.287-3.402-6.861l1.583-1.81A11 11 0 0 1 24 12Zm-4.722 0a6.288 6.288 0 0 1-2.614 5.108l-1.592-1.819a3.893 3.893 0 0 0 1.806-3.288c0-1.383-.72-2.597-1.806-3.289l1.592-1.82a6.288 6.288 0 0 1 2.614 5.109ZM6 8.5 12.5 4v16L6 15.5H2.5v-7H6Z" fill="#fff"></path>
                    </svg>
                  )}
                </div>
              )}
              <div className="overlay_background_poster_anime"></div>
            </div>

            <div className='cont_info_iframe_anime_body'>
                {apiData ? (
                  <div className='cont_info_iframe_anime_body_logo'>
                    <img src={apiData.logo} />
                  </div>
                ) : hasError ? (
                  <div className='cont_info_iframe_anime_body_title_ru'>{anime.russian}</div>
                ) : (
                  <></>
                )}
                <p className='anime_main_block_kind_year_genres'>
                    <span className={`rank_anime ${
                        anime.score >= 8 ? 'search_rank_hard_score' : anime.score <= 7 ? 'search_rank_low_score' : 'search_rank_high_score'
                    }`}>
                    {renderScoreWithSVG(anime.score)}
                    </span>
                    <span className='anime_main_block_between'>|</span>
                    <span>{statusTranslations[anime.status] || anime.status}</span>
                    <span className='anime_main_block_between'>|</span>
                    <span>
                    {kindTranslations[anime.kind] || anime.kind}
                    </span>
                    <span className='anime_main_block_between'>|</span>
                    <span>{anime.episodesAired} эп</span>
                    <span className='anime_main_block_between'>|</span>
                    <span>
                    {anime.airedOn ? anime.airedOn.year : 'Не указано'}
                    </span>
                    <span className='anime_main_block_between'>|</span>
                    <span>
                    {anime.genres.filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
                        .map((genre) => genre.russian)
                        .join(', ')}
                    </span>
                </p>
                <div className='cont_info_iframe_anime_body_description_text'>{cleanDescription(anime.description)}</div>
                <div className='auxiliary_butt'>
                    {isHentai || anime.status === 'anons' ? (
                    <div className='auxiliary_butt_watch disabled' style={{cursor: 'not-allowed'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_429_11057)">
                            <path d="M5.63604 5.63598C4.00736 7.26466 3 9.51466 3 11.9999C3 16.9705 7.02944 20.9999 12 20.9999C14.4853 20.9999 16.7353 19.9926 18.364 18.3639M5.63604 5.63598C7.26472 4.0073 9.51472 2.99994 12 2.99994C16.9706 2.99994 21 7.02938 21 11.9999C21 14.4852 19.9926 16.7352 18.364 18.3639M5.63604 5.63598L18.364 18.3639" stroke="#292929" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_429_11057">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                        </svg>
                        Недоступно
                    </div>
                    ) : (
                    <a href={`/play/${anime.id}`}>
                        <div className='auxiliary_butt_watch'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="-0.5 0 8 8" version="1.1">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Dribbble-Light-Preview" transform="translate(-427.000000, -3765.000000)" fill="#000000">
                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                            <polygon id="play-[#1001]" points="371 3605 371 3613 378 3609"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            {isFetchedFromServer ? 'Смотреть в 4К' : 'Смотреть'}
                        </div>
                    </a>
                    )}
                    <div className='auxiliary_butt_favourites' onClick={handleAddToRemembered}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill={isRemembered ? "#eb6285" : "#ffff"}>
                        <path d="M5.5 3.5h13V21L12 17.45 5.5 21V3.5Z"></path>
                      </svg>
                    </div>

                    <div className='auxiliary_butt_favourites' onClick={handleAddToViewed}>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill={isViewed ? "#eb6285" : "#ffff"}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.33 14.485c.757-.698 1.4-1.4 1.891-1.985a21.948 21.948 0 0 0-1.892-1.985C16.422 8.76 14.182 7.4 12 7.4c-2.182 0-4.422 1.36-6.33 3.115-.757.698-1.4 1.4-1.891 1.985a21.94 21.94 0 0 0 1.892 1.985C7.578 16.24 9.818 17.6 12 17.6c2.182 0 4.422-1.36 6.33-3.115ZM12 5C5.787 5 .75 12.5.75 12.5S5.787 20 12 20s11.25-7.5 11.25-7.5S18.213 5 12 5Zm9.26 6.159ZM15 12.499a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path>
                      </svg>
                    </div>
                </div>
            </div>
            <div className='space_top_left_anime_details_info_cont' style={{position: 'absolute', width: '40%', left: '50%', transform: 'translateX(-50%)', bottom: '0', padding: '15px'}}>
                {anime.status === 'ongoing' && (
                <div className='anime_new_episode_block'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    Следующая серия выйдет
                    </div>
                    <div>{formatDate(anime.nextEpisodeAt)}</div> {/* Форматирование даты */}
                </div>
                )}
                {anime.status === 'anons' && (
                <div className='anime_date_release_block'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    Дата выпуска
                    </div>
                    <div>{anime.airedOn.date ? formatDate(anime.airedOn.date) : 'Неизвестно'}</div>
                </div>
                )}
                {anime.genres.some((genre) => genre.name === 'Hentai') && (
                <div className='anime_date_release_block'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none" style={{marginRight: '5px'}}>
                        <path d="M12 7V13M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <circle cx="12" cy="16.5" r="1" fill="#ffff"/>
                    </svg>
                    Доступ ограничен в связи с обращением правообладателя.
                    </div>
                    <div></div>
                </div>
                )}
            </div>
        </div>

        <div className='anime_details_info_cont'>
            <div className='left_anime_details_info_cont'>
                <div className='space_top_left_anime_details_info_cont anime_body_right_info_description'>
                    <span>Описание</span>
                    <div className='space_top_left_anime_details_info_cont_descript'>
                        {cleanDescription(anime.description)}
                    </div>
                </div>
            </div>
            <div className='right_anime_details_info_cont'>
                <div className='anime_body_right_info_detail'>
                <span>Об {kindTranslationsInfo[anime.kind] || anime.kind}</span>
                <div className='anime_body_right_info_detail_yers'>
                    <span>Год производства</span>
                    <div>{anime.airedOn ? anime.airedOn.year : 'Не указано'}</div>
                </div>
                <div className='anime_body_right_info_detail_country'>
                    <span>Страна</span>
                    <div>Япония</div>
                </div>
                <div className='anime_body_right_info_detail_tip'>
                    <span>Тип</span>
                    <div>{kindTranslations[anime.kind] || anime.kind}</div>
                </div>
                <div className='anime_body_right_info_detail_atelier'>
                    <span>Студия</span>
                    <div>{anime.studios.length > 0 ? anime.studios[0].name : 'Не указано'}</div>
                </div>
                <div className='anime_body_right_info_detail_episode'>
                    <span>Эпизоды</span>
                    <div>{anime.episodesAired}</div>
                </div>
                <div className='anime_body_right_info_detail_time_episode'>
                    <span>Длительность эпизода</span>
                    <div>{anime.duration} мин</div>
                </div>
                <div className='anime_body_right_info_detail_status'>
                    <span>Статус</span>
                    <div>{statusTranslations[anime.status] || anime.status}</div>
                </div>
                <div className='anime_body_right_info_detail_genre'>
                    <span>Жанры</span>
                    <div>                
                    {anime.genres
                    .filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
                    .map((genre) => genre.russian)
                    .join(', ')}
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div className='anime_central_photos_block'>
            <span>Кадры</span>
            <div className='list_catalog_photos'>
                <div className="left_button_3" onClick={scrollLeft}>
                  <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(0deg)'}}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="#ababab"></path>
                  </svg>
                </div>
                <div className="right_button_3" onClick={scrollRight}>
                  <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(180deg)'}}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="#ababab"></path>
                  </svg>
                </div>
                <div className='episode_list_' ref={episodeListRef}>
                    {anime.screenshots.map((screenshot) => (
                    <div
                        key={screenshot.id}
                        className='anime_central_photo_'
                        style={{ backgroundImage: `url('${screenshot.originalUrl}')` }}
                        onClick={() => openViewer(screenshot.originalUrl)} // Открываем изображение при клике
                    ></div>
                    ))}
                </div>
            </div>
        </div>

        {isViewerOpen && (
            <div className='photo_viewer_container' onClick={closeViewer}>
            <div
                className='photo_viewer_image'
                style={{
                backgroundImage: `url(${viewerImageUrl})`,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                width: '65%',
                aspectRatio: '16 / 9',
                backgroundPosition: '50%',
                backgroundSize: 'cover',
                objectFit: 'cover',
                zIndex: '112',
                }}
                onClick={(e) => e.stopPropagation()}
            ></div>
            </div>
        )}
        
        <div className='anime_center_comments_block'>
          <div className='anime_center_comments_block_title'>Комментарии</div>
          <form onSubmit={handleCommentSubmit} className='profile_comments_anime'>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Напишите ваш комментарий..."
              required
            />
            <button type="submit">Оставить комментарий</button>
          </form>
          <div className='anime_center_comments_block_list'>
            {comments.map((comment) => (
              <div className='anime_center_comments_users_comm' key={comment.id}>
                <div className='anime_center_comments_user_info'>
                  <div style={{ backgroundImage: `url(${comment.avatar})` }}></div>
                  <span>{comment.login}</span>
                </div>
                <div className='anime_center_comments_user_text'>
                  <p>{comment.comment}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ height: '140px' }}></div>
        <Footer />
    </>
  )
}
