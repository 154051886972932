import React, { useEffect, useRef, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL query to get anime announcements
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(kind: "tv,movie", status: "anons", limit: 100, order: popularity) {
      id
      russian
      score
      episodes
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function AllOpenList() {
  const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);
  const [expandedItem, setExpandedItem] = useState(null);
  const [serverData, setServerData] = useState([]);
  const [imageUrls, setImageUrls] = useState({}); // State to hold image URLs
  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingAnimeData, setLoadingAnimeData] = useState(true); // Новое состояние
  const [fetchedAnimeData, setFetchedAnimeData] = useState({});

  // Функция для получения данных с вашего сервера
  const fetchServerData = async () => {
    try {
      const response = await axios.get('https://api.aniplay.fun/anime');
      setServerData(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch server data when component mounts
  useEffect(() => {
    fetchServerData();
  }, []);

  // Функция для проверки наличия аниме в серверных данных
  const findAnimeOnServer = (id) => {
    return serverData.find((anime) => anime.id === Number(id));
  };

  const handleItemClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const cleanDescription = (description) => {
    return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
  };

    // Function to fetch data from the API
    const fetchAnimeData = async (animeId) => {
      try {
        const response = await axios.get(`https://api.aniplay.fun/storage/${animeId}/`);
        console.log('API Response:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching anime data:', error);
        return null;
      }
    };
    
    // Загрузка изображений
    useEffect(() => {
      const fetchImages = async () => {
        if (!data || !data.animes) return;
    
        setLoadingImages(true);
        const urls = await Promise.all(
          data.animes.map(async (anime) => {
            const animeData = await fetchAnimeData(anime.id);
            return animeData ? animeData.sap : anime.poster.originalUrl;
          })
        );
        setImageUrls(urls);
        setLoadingImages(false);
      };
    
      fetchImages();
    }, [data]);
    
    // Загрузка данных аниме
    useEffect(() => {
      const fetchAllAnimeData = async () => {
        if (!data || !data.animes) return;
    
        setLoadingAnimeData(true);
        const animeDataPromises = data.animes.map(async (anime) => {
          const animeData = await fetchAnimeData(anime.id);
          return { id: anime.id, data: animeData };
        });
    
        const results = await Promise.all(animeDataPromises);
        
        const animeDataMap = results.reduce((acc, item) => {
          acc[item.id] = item.data;
          return acc;
        }, {});
    
        setFetchedAnimeData(animeDataMap);
        setLoadingAnimeData(false);
      };
    
      fetchAllAnimeData();
    }, [data]);

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
          {score}
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }


  if (loading || loadingImages || loadingAnimeData) return (
    <div className='all_header'>
      <div className='open_header'>
          <span>Скоро на Аниплей</span>
      </div>
      <div className='open_list'>
        {Array.from({ length: 24 }, (_, index) => (
          <div key={index} className='item_open_list' style={{ display: "block" }}>
            <div className='item_all loadbar_item_all'>
              <div className='loading-bar'></div>
            </div>
            <div className='overlay'></div>
            <div class="item_name loadbar_item_name">
              <div className='loading-bar'></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );


  if (error) return <p>Error: {error.message}</p>;


  const animes = data?.animes || [];

  return (
    <div className='all_header'>
      <div className='open_header'>
          <span>Скоро на Аниплей</span>
      </div>
      <div className='open_list'>
        {animes.map((anime, index) => {
          const serverAnime = findAnimeOnServer(anime.id);
          const displayAnime = serverAnime || anime;

          const animeFetchedData = fetchedAnimeData[anime.id];

          return (
            <div key={anime.id} className='item_open_list'>
              <a href={`/anime/${anime.id}`}>
                <div className='item_all'>
                  {!animeFetchedData && <div className='poster_overlay'></div>}
                  <img
                    src={imageUrls[index] || anime.poster.originalUrl} // Use the fetched URL or fallback to originalUrl
                    alt={anime.name}
                  />
                  {!animeFetchedData && (
                    <div className="item_name">{anime.russian || anime.name}</div>
                  )}
                  <div className='overlay'></div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
