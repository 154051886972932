import React, { useEffect, useRef, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL запрос для получения аниме со статусом "анонс"
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(kind: "movie", status: "released", limit: 15, order: ranked_random, rating: "pg_13") {
      id
      name
      russian
      score
      episodes
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function ListCatalog() {
  const scrollContainerRef = useRef(null);
  const scrollContainerRefEpisode = useRef(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [serverData, setServerData] = useState([]);
  const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);
  const [showButtons, setShowButtons] = useState({ left: true, right: true });
  const [showButtonsEpisode, setShowButtonsEpisode] = useState({ left: true, right: true });

  const [selectedAnime, setSelectedAnime] = useState(null);

  const [episodes, setEpisodes] = useState([]);

  const [loadingEpisodes, setLoadingEpisodes] = useState(false);

  const [materialData, setMaterialData] = useState(null);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.clientWidth; // Получаем ширину контейнера
      scrollContainerRef.current.scrollBy({
        left: -containerWidth * 0.8, // Прокрутка на 80% ширины контейнера
        behavior: 'smooth',
      });
    }
  };
  
  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.clientWidth; // Получаем ширину контейнера
      scrollContainerRef.current.scrollBy({
        left: containerWidth * 0.8, // Прокрутка на 80% ширины контейнера
        behavior: 'smooth',
      });
    }
  };

  const handleScrollLeftEpisode = () => {
    if (scrollContainerRefEpisode.current) {
      const containerWidth = scrollContainerRefEpisode.current.clientWidth; // Получаем ширину контейнера
      scrollContainerRefEpisode.current.scrollBy({
        left: -containerWidth * 0.8, // Прокрутка на 80% ширины контейнера
        behavior: 'smooth',
      });
    }
  };
  
  const handleScrollRightEpisode = () => {
    if (scrollContainerRefEpisode.current) {
      const containerWidth = scrollContainerRefEpisode.current.clientWidth; // Получаем ширину контейнера
      scrollContainerRefEpisode.current.scrollBy({
        left: containerWidth * 0.8, // Прокрутка на 80% ширины контейнера
        behavior: 'smooth',
      });
    }
  };

  const handleItemClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const cleanDescription = (description) => {
    return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
  };

  // Функция для проверки наличия аниме в серверных данных
  const findAnimeOnServer = (id) => {
    return serverData.find((anime) => anime.id === Number(id));
  };

  // Функция для получения жанров в нужном формате
  const getGenres = (anime) => {
    if (Array.isArray(anime.genres) && anime.genres.length > 0) {
      return anime.genres
        .filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
        .map((genre) => genre.russian)
        .join(', ');
    } else if (typeof anime.genres === 'string' && anime.genres.trim() !== '') {
      return anime.genres;
    } else {
      return 'Нет жанров';
    }
  };

  const updateButtonVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowButtons({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth,
      });
    }
  };

  const updateButtonVisibilityEpisode = () => {
    if (scrollContainerRefEpisode.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRefEpisode.current;
      setShowButtonsEpisode({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth,
      });
    }
  };

  useEffect(() => {
    updateButtonVisibility();
    const currentContainer = scrollContainerRef.current;
    currentContainer.addEventListener('scroll', updateButtonVisibility);
    
    return () => {
      currentContainer.removeEventListener('scroll', updateButtonVisibility);
    };
  }, [serverData]);

  useEffect(() => {
    updateButtonVisibilityEpisode();
    const currentContainer = scrollContainerRefEpisode.current;
  
    if (currentContainer) {
      currentContainer.addEventListener('scroll', updateButtonVisibilityEpisode);
      
      return () => {
        currentContainer.removeEventListener('scroll', updateButtonVisibilityEpisode);
      };
    }
  }, [episodes]); // Update dependencies to include episodes

  useEffect(() => {
    // Функция для закрытия всех элементов, если клик произошел за пределами list_catalog
    const handleOutsideClick = (event) => {
      if (scrollContainerRef.current && !scrollContainerRef.current.contains(event.target)) {
        setExpandedItem(null);
      }
    };
  
    // Добавляем слушатель событий для кликов вне элемента list_catalog
    document.addEventListener('mousedown', handleOutsideClick);
  
    return () => {
      // Удаляем слушатель при размонтировании компонента
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);




  const [selectedTranslation, setSelectedTranslation] = useState(null);

  const getEpisodesRange = (lastEpisode, episodesCount) => {
    const startEpisode = lastEpisode - episodesCount + 1;
    return Array.from({ length: episodesCount }, (_, i) => i + startEpisode).map(
      (episode) => `${episode} эпизод`
    );
  };

  const [logo, setLogo] = useState(null);

  const fetchLogo = async (animeId) => {
    try {
      const logoResponse = await fetch(`https://api.aniplay.fun/storage/${animeId}/`);
      const logoData = await logoResponse.json();
      
      return logoData; 
    } catch (error) {
      console.error('Ошибка при получении логотипа:', error);
      return null; 
    }
  };

const fetchEpisodes = async (animeId) => {
  setLoadingEpisodes(true);
  const token = process.env.REACT_APP_KODIK_TOKEN;

  // Запускаем получение логотипа и данных серий параллельно
  const logoPromise = fetchLogo(animeId);
  const url = `https://kodikapi.com/search?token=${token}&shikimori_id=${animeId}&with_episodes_data=true&with_material_data=true`;

  try {
      const [logoData, response] = await Promise.all([logoPromise, fetch(url)]);

      if (!response.ok) {
          throw new Error('Ошибка при загрузке серий');
      }
      const result = await response.json();

      if (result && result.results && result.results.length > 0) {
          const animeData = result.results[0];

          // Сохраняем только material_data в состоянии
          setMaterialData(animeData.material_data); 

          // Сохраняем логотип отдельно
          const logoUrl = logoData ? logoData.logo : null; 
          setLogo(logoUrl); // Добавляем новое состояние для логотипа

          // Обработка эпизодов
          if (animeData && animeData.seasons) {
              const episodesArray = Object.values(animeData.seasons).flatMap(season => {
                  return Object.values(season.episodes || {}).map((episode, index) => ({
                      id: episode.id,
                      title: episode.title || `Эпизод ${index + 1}`,
                      screenshots: episode.screenshots || []
                  }));
              });
              setEpisodes(episodesArray);
          } else {
              setEpisodes([]);
          }
      } else {
          setEpisodes([]);
      }
  } catch (error) {
      console.error('Ошибка при получении серий:', error);
      setEpisodes([]);
  } finally {
      setLoadingEpisodes(false);
  }
};










  
  const [isActive, setIsActive] = useState(false);
  const catalogContainerRef = useRef(null); // Ссылка на контейнер

  const [selectedIndex, setSelectedIndex] = useState(null); 

  const [fadeOpacity, setFadeOpacity] = useState(1);

  const handleItemEpisodeClick = (index) => {

    if (isActive && selectedIndex === index) {
      return;
    }

    if (selectedIndex === index) {
        // Если текущий индекс совпадает с выбранным, просто закройте
        closeEpisodeSelection();
        return;
    }

    setFadeOpacity(0); // Start fade out

    setTimeout(() => {
        setSelectedIndex(index);
        const selected = data.animes[index];
        setSelectedAnime(selected);
        fetchEpisodes(selected.id); // Fetch episodes for the selected anime

        // After updating the data, set the fade opacity to 1 for fade in
        setFadeOpacity(1); // Start fade in

        // Set a timeout to remove the slow class after the fade-in duration
        setTimeout(() => {
            document.querySelector('.style_fade').classList.remove('slow');
        }, 600); // Duration for slow fade-out
    }, 600);

    setIsActive(true); // Set the active state to true
    
    setActiveTab('tab_watch');

    if (catalogContainerRef.current) {
        const container = catalogContainerRef.current;
        const containerRect = container.getBoundingClientRect();
        const scrollToPosition = window.scrollY + containerRect.top - (window.innerHeight / 3.4);

        window.scrollTo({
            top: scrollToPosition,
            behavior: 'smooth'
        });
    }
  };


  const closeEpisodeSelection = () => {
    setSelectedAnime(null); // Reset selected anime
    setIsActive(false); // Set active state to false
    setSelectedIndex(null); // Reset selected index to allow reopening
  };

  const [activeTab, setActiveTab] = useState('tab_watch'); // состояние для активного таба

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };


  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingAnimeData, setLoadingAnimeData] = useState(true); // Новое состояние
  const [fetchedAnimeData, setFetchedAnimeData] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  
  const fetchAnimeData = async (animeId) => {
    try {
      const response = await axios.get(`https://api.aniplay.fun/storage/${animeId}/`);
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching anime data:', error);
      return null;
    }
  };
  
  // Загрузка изображений
  useEffect(() => {
    const fetchImages = async () => {
      if (!data || !data.animes) return;
  
      setLoadingImages(true);
      const urls = await Promise.all(
        data.animes.map(async (anime) => {
          const animeData = await fetchAnimeData(anime.id);
          return animeData ? animeData.sap : anime.poster.originalUrl;
        })
      );
      setImageUrls(urls);
      setLoadingImages(false);
    };
  
    fetchImages();
  }, [data]);
  
  // Загрузка данных аниме
  useEffect(() => {
    const fetchAllAnimeData = async () => {
      if (!data || !data.animes) return;
  
      setLoadingAnimeData(true);
      const animeDataPromises = data.animes.map(async (anime) => {
        const animeData = await fetchAnimeData(anime.id);
        return { id: anime.id, data: animeData };
      });
  
      const results = await Promise.all(animeDataPromises);
      
      const animeDataMap = results.reduce((acc, item) => {
        acc[item.id] = item.data;
        return acc;
      }, {});
  
      setFetchedAnimeData(animeDataMap);
      setLoadingAnimeData(false);
    };
  
    fetchAllAnimeData();
  }, [data]);



  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
          {score}
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }

  if (loading || loadingImages || loadingAnimeData) return (
    <div className='list_catalog'>
        <div class="list_catalog_header">
          <span>Фильмы</span>
        </div>
        <div className="left_button_1" onClick={handleScrollLeft} style={{ opacity: showButtons.left ? 1 : 0 }}>
          <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(0deg)'}}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
          </svg>
        </div>
        <div className="right_button_1" onClick={handleScrollRight} style={{ opacity: showButtons.right ? 1 : 0 }}>
          <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(180deg)'}}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
          </svg>
        </div>
        <div className='list_episode' ref={scrollContainerRef}>
        {[
          0, 1, 2, 3, 4, 5, 6
        ].map((index) => (
          <div 
            key={index}
            className='catalog_item_loadbar'
            onClick={() => handleItemClick(index)}
          >
            <div className='item loadbar_item'>
              <div className='loading-bar'></div>
            </div>
            <div class="overlay"></div>
            <div class="item_name loadbar_item_name">
              <div className='loading-bar'></div>
            </div>
          </div>
        ))}
        </div>
    </div>
  );

  if (error) return (
    <div className='list_catalog'>
        <div class="list_catalog_header">
          <span>Фильмы</span>
        </div>
        <div className="left_button_1" onClick={handleScrollLeft} style={{ opacity: showButtons.left ? 1 : 0 }}>
          <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(0deg)'}}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
          </svg>
        </div>
        <div className="right_button_1" onClick={handleScrollRight} style={{ opacity: showButtons.right ? 1 : 0 }}>
          <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(180deg)'}}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
          </svg>
        </div>
        <div className='list_episode' ref={scrollContainerRef}>
        {[
          0, 1, 2, 3, 4, 5, 6
        ].map((index) => (
          <div 
            key={index}
            className={`catalog_item ${expandedItem === index ? 'expanded' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            <div className='item loadbar_item'>
              <div className='loading-bar'></div>
            </div>
            <div class="overlay"></div>
            <div class="item_name loadbar_item_name">
              <div className='loading-bar'></div>
            </div>
          </div>
        ))}
        </div>
    </div>
  );

  return (
    <div className="list_catalog">
      <div className="list_catalog_header">
        <a href='/all=3'>
          <span>
            Фильмы
            <svg width="20" height="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" class="ListHeader_title-link-icon__eRZGD BaseList_title-link-icon__e5Eln" aria-hidden="true" data-tid="Angle"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.3 9.988 5.558 3.53l1.384-1.444 7.5 7.186.758.727-.763.722-7.5 7.091-1.374-1.453 6.737-6.37Z"></path></svg>
          </span>
        </a>
      </div>
      <div className="left_button_1" onClick={handleScrollLeft} style={{ opacity: showButtons.left ? 1 : 0 }}>
        <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(0deg)'}}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
        </svg>
      </div>
      <div className="right_button_1" onClick={handleScrollRight} style={{ opacity: showButtons.right ? 1 : 0 }}>
        <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(180deg)'}}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
        </svg>
      </div>
      <div className="list_episode" ref={scrollContainerRef}>
        {data.animes.map((anime, index) => {
          const serverAnime = findAnimeOnServer(anime.id);
          const displayAnime = serverAnime || anime;

          // Проверяем, есть ли данные с API
          const animeFetchedData = fetchedAnimeData[anime.id];

          return (
            <div
              key={displayAnime.id}
              className='catalog_item'
              onClick={() => handleItemEpisodeClick(index)}
            >
              <div className={`item ${isActive && selectedIndex === index ? 'item_border' : ''}`}>
                <div className="container_catalog_item_tag">
                  {serverAnime && (
                    <div className='item_tag' style={{ backgroundColor: '#ff3f3f', color: 'white', fontWeight: '500' }}>4K</div>
                  )}
                  <div
                    className={`item_tag ${
                      displayAnime.score >= 8 ? 'hard_score' : displayAnime.score <= 7 ? 'low_score' : 'high_score'
                    }`}
                  >
                    {renderScoreWithSVG(displayAnime.score.toFixed(1))}
                  </div>
                </div>

                {!animeFetchedData && <div className='poster_overlay'></div>}

                <img
                  src={imageUrls[index] || anime.poster.originalUrl}
                  alt={anime.name}
                />

                {!animeFetchedData && (
                  <div className="item_name">{anime.russian || anime.name}</div>
                )}

                <div className="overlay"></div>
                <div className='border_point' style={{ visibility: isActive && selectedIndex === index ? 'visible' : 'hidden' }}></div>
              </div>
            </div>
          );
        })}
        <div className='open_more_item_list'>
          <a href='/all=3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
                <path d="M13.2328 16.4569C12.9328 16.7426 12.9212 17.2173 13.2069 17.5172C13.4926 17.8172 13.9673 17.8288 14.2672 17.5431L13.2328 16.4569ZM19.5172 12.5431C19.8172 12.2574 19.8288 11.7827 19.5431 11.4828C19.2574 11.1828 18.7827 11.1712 18.4828 11.4569L19.5172 12.5431ZM18.4828 12.5431C18.7827 12.8288 19.2574 12.8172 19.5431 12.5172C19.8288 12.2173 19.8172 11.7426 19.5172 11.4569L18.4828 12.5431ZM14.2672 6.4569C13.9673 6.17123 13.4926 6.18281 13.2069 6.48276C12.9212 6.78271 12.9328 7.25744 13.2328 7.5431L14.2672 6.4569ZM19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25V12.75ZM5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75V11.25ZM14.2672 17.5431L19.5172 12.5431L18.4828 11.4569L13.2328 16.4569L14.2672 17.5431ZM19.5172 11.4569L14.2672 6.4569L13.2328 7.5431L18.4828 12.5431L19.5172 11.4569ZM19 11.25L5 11.25V12.75L19 12.75V11.25Z" fill="white"/>
            </svg>
          </a>
          <span>Показать все</span>
        </div>
      </div>
      <div className={`style_fade ${fadeOpacity === 1 ? 'slow' : ''}`} style={{ opacity: fadeOpacity }}>
        <div className={`catalog_choice_episode_container ${isActive ? 'active_episode' : ''}`} ref={catalogContainerRef}>
          {selectedAnime ? (
            <>
              <img className={`catalog_choice_episode_container_back_poster ${activeTab === 'tab_details' ? 'detail' : ''}`} src={selectedAnime.poster.originalUrl} />
              <div className='shadow_catalog_choice_episode_container_back_poster'></div>
              <div className='container_chice_episode'>
                <div className='container_header_list_chice_episode'>
                  <div 
                    className={`header_list_chice_episode ${activeTab === 'tab_watch' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tab_watch')}
                  >
                    Просмотр
                  </div>
                  <div 
                    className={`header_list_chice_episode ${activeTab === 'tab_details' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tab_details')}
                  >
                    Детали
                  </div>
                </div>
                <div className='close_list_chice_episode' onClick={closeEpisodeSelection}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="37px" height="37px" viewBox="0 -0.5 25 25" fill="none">
                    <path d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z" fill="#ffff"/>
                  </svg>
                </div>
                <p className={`list_chice_episode_title ${activeTab === 'tab_details' ? 'detail' : ''}`}>
                    {loadingEpisodes ? ( // Проверяем, загружаются ли данные
                        <span>
                            Загрузка...
                        </span>
                    ) : logo ? ( // Проверяем, существует ли логотип
                        <div className='list_chice_episode_title_img'>
                            <img src={logo} alt="Логотип аниме"/> {/* Используем новое состояние для логотипа */}
                        </div>
                    ) : (
                        <span>{selectedAnime.russian}</span> // Резервный вариант: имя аниме, если логотип недоступен
                    )}
                </p>
                {activeTab === 'tab_watch' ? (
                  <div className='tab_watch'>
                    <div className="left_button_5" onClick={handleScrollLeftEpisode} style={{ opacity: showButtonsEpisode.left ? 1 : 0 }}>
                      <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(0deg)'}}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
                      </svg>
                    </div>
                    <div className="right_button_5" onClick={handleScrollRightEpisode} style={{ opacity: showButtonsEpisode.right ? 1 : 0 }}>
                      <svg width="40" height="36" viewBox="0 0 17 36" fill="none" xmlns="http://www.w3.org/2000/svg" data-tid="Arrow" style={{transform: 'rotate(180deg)'}}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.5001 17.9998L16.6001 3.1998L13.4001 0.799805L0.500097 17.9998L13.4001 35.1998L16.6001 32.7998L5.5001 17.9998Z" fill="white"></path>
                      </svg>
                    </div>
                    <div className='list_chice_episode' ref={scrollContainerRefEpisode}>
                      {loadingEpisodes ? (
                        [
                          0, 1, 2, 3, 4, 5, 6
                        ].map((index) => (
                          <div 
                            key={index}
                            className='catalog_episode_item'
                            onClick={() => handleItemClick(index)}
                          >
                            <div className='item_episode loadbar_item'>
                              <div className='loading-bar'></div>
                            </div>
                            <div className="overlay"></div>
                          </div>
                        ))
                      ) : episodes.length > 0 ? (
                        episodes.map((episode, index) => {
                          const episodeScreenshots = episode.screenshots || [];
                          return (
                              <div className='catalog_episode_item' key={episode.id}>
                                <a href={`/play/${selectedAnime.id}?episode=${index + 1}`}>
                                  <div className='item_episode'>
                                    {episodeScreenshots.length > 0 ? (
                                      <img src={episodeScreenshots[0]} alt={`Скриншот эпизода ${index + 1}`} />
                                    ) : (
                                      <img src="../icons/item.jpg" alt="Эпизод" />
                                    )}
                                    <div className='item_episode_play'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="-0.5 0 8 8" version="1.1">
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <g id="Dribbble-Light-Preview" transform="translate(-427.000000, -3765.000000)" fill="#0f0f0f">
                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                              <polygon id="play-[#1001]" points="371 3605 371 3613 378 3609"></polygon>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                      <div className='episode_item_name_circle'></div>
                                      {episode.title ? episode.title : `Эпизод ${index + 1}`}
                                    </div>
                                    <div className='episode_item_name_desc'>Нет описания</div>
                                  </div>
                                </a>
                              </div>
                          );
                        })
                      ) : (
                        <div className='fale_episode'>Нет доступных эпизодов</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='tab_details'>
                        <div className='tab_details_space_1'>
                          <div className='list_chice_episode_titile_en'>{selectedAnime.name}</div>
                          <span className={`list_chice_episode_scrol ${
                                  selectedAnime.score >= 8 ? 'search_rank_hard_score' : 
                                  selectedAnime.score <= 7 ? 'search_rank_low_score' : 
                                  'search_rank_high_score'
                              }`}>
                                  {selectedAnime.score}
                              </span>
                          <div className='list_chice_episode_num_scrol'>{materialData && materialData.imdb_votes ? `${materialData.imdb_votes} оценок` : 'Нет оценок'}</div>
                          <div className='list_chice_episode_description'>{cleanDescription(selectedAnime.description)}</div>
                          <div className='list_chice_episode_detail'>
                            <a href={`/anime/${selectedAnime.id}`}>
                              Подробнее на аниплей
                              <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                                <path d="M7 17L17 7M17 7H8M17 7V16" stroke="#ffffffa6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </a>
                          </div>
                        </div>
                    </div>
                      <div className='tab_details_space_2'>
                      <div className='tab_details_space_2_block_1'>
                        <span>В главных ролях</span>
                        {materialData && materialData.actors && materialData.actors.length > 0 ? (
                            materialData.actors.slice(0, 5).map((actor, index) => (
                                <div key={index}>{actor}</div>
                            ))
                        ) : (
                            <div>Нет информации</div>
                        )}
                        <div style={{height: '24px'}}></div>
                        <span>Режиссёры</span>
                        {materialData && materialData.directors && materialData.directors.length > 0 ? (
                            materialData.directors.slice(0, 3).map((director, index) => (
                                <div key={index}>{director}</div>
                            ))
                        ) : (
                            <div>Нет информации</div>
                        )}
                      </div>
                      <div className='tab_details_space_2_block_2'>
                        <span>Студия</span>
                        <div>{materialData ? materialData.anime_studios : 'Нет информации'}</div>
                        <div style={{height: '24px'}}></div>
                        <span>Страна</span>
                        <div>{materialData ? materialData.countries : 'Нет информации'}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}