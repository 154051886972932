import React, { Component } from 'react'

export default class flask extends Component {
  render() {
    return (
        <>
            <div class="related" role="navigation" aria-label="related navigation">
      <h3>Navigation</h3>
      <ul>
        <li class="right">
          <a href="https://flask-docs.readthedocs.io/../../genindex/" title="General Index"
             accesskey="I">index</a></li>
        <li class="right" >
          <a href="https://flask-docs.readthedocs.io/../../py-modindex/" title="Python Module Index"
             >modules</a> |</li>
        <li class="right" >
          <a href="https://flask-docs.readthedocs.io/../sqlalchemy/" title="SQLAlchemy in Flask"
             accesskey="N">next</a> |</li>
        <li class="right" >
          <a href="https://flask-docs.readthedocs.io/../urlprocessors/" title="Using URL Processors"
             accesskey="P">previous</a> |</li>
        <li class="nav-item nav-item-0"><a href="https://flask-docs.readthedocs.io/../../">Flask Documentation (2.3.x)</a> &#187;</li>
          <li class="nav-item nav-item-1"><a href="https://flask-docs.readthedocs.io/../" accesskey="U">Patterns for Flask</a> &#187;</li>
        <li class="nav-item nav-item-this"><a href="https://flask-docs.readthedocs.io/">Using SQLite 3 with Flask</a></li> 
      </ul>
    </div>  

    <div class="document">
      <div class="documentwrapper">
        <div class="bodywrapper">
          <div class="body" role="main">
            
  <section id="using-sqlite-3-with-flask">
<h1>Using SQLite 3 with Flask<a class="headerlink" href="https://flask-docs.readthedocs.io/#using-sqlite-3-with-flask" title="Permalink to this headline">¶</a></h1>
<p>In Flask you can easily implement the opening of database connections on
demand and closing them when the context dies (usually at the end of the
request).</p>
<p>Here is a simple example of how you can use SQLite 3 with Flask:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="kn">import</span> <span class="nn">sqlite3</span>
<span class="kn">from</span> <span class="nn">flask</span> <span class="kn">import</span> <span class="n">g</span>

<span class="n">DATABASE</span> <span class="o">=</span> <span class="s1">&#39;/path/to/database.db&#39;</span>

<span class="k">def</span> <span class="nf">get_db</span><span class="p">():</span>
    <span class="n">db</span> <span class="o">=</span> <span class="nb">getattr</span><span class="p">(</span><span class="n">g</span><span class="p">,</span> <span class="s1">&#39;_database&#39;</span><span class="p">,</span> <span class="kc">None</span><span class="p">)</span>
    <span class="k">if</span> <span class="n">db</span> <span class="ow">is</span> <span class="kc">None</span><span class="p">:</span>
        <span class="n">db</span> <span class="o">=</span> <span class="n">g</span><span class="o">.</span><span class="n">_database</span> <span class="o">=</span> <span class="n">sqlite3</span><span class="o">.</span><span class="n">connect</span><span class="p">(</span><span class="n">DATABASE</span><span class="p">)</span>
    <span class="k">return</span> <span class="n">db</span>

<span class="nd">@app</span><span class="o">.</span><span class="n">teardown_appcontext</span>
<span class="k">def</span> <span class="nf">close_connection</span><span class="p">(</span><span class="n">exception</span><span class="p">):</span>
    <span class="n">db</span> <span class="o">=</span> <span class="nb">getattr</span><span class="p">(</span><span class="n">g</span><span class="p">,</span> <span class="s1">&#39;_database&#39;</span><span class="p">,</span> <span class="kc">None</span><span class="p">)</span>
    <span class="k">if</span> <span class="n">db</span> <span class="ow">is</span> <span class="ow">not</span> <span class="kc">None</span><span class="p">:</span>
        <span class="n">db</span><span class="o">.</span><span class="n">close</span><span class="p">()</span>
</pre></div>
</div>
<p>Now, to use the database, the application must either have an active
application context (which is always true if there is a request in flight)
or create an application context itself.  At that point the <code class="docutils literal notranslate"><span class="pre">get_db</span></code>
function can be used to get the current database connection.  Whenever the
context is destroyed the database connection will be terminated.</p>
<p>Example:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="nd">@app</span><span class="o">.</span><span class="n">route</span><span class="p">(</span><span class="s1">&#39;/&#39;</span><span class="p">)</span>
<span class="k">def</span> <span class="nf">index</span><span class="p">():</span>
    <span class="n">cur</span> <span class="o">=</span> <span class="n">get_db</span><span class="p">()</span><span class="o">.</span><span class="n">cursor</span><span class="p">()</span>
    <span class="o">...</span>
</pre></div>
</div>
<div class="admonition note">
<p class="admonition-title">Note</p>
<p>Please keep in mind that the teardown request and appcontext functions
are always executed, even if a before-request handler failed or was
never executed.  Because of this we have to make sure here that the
database is there before we close it.</p>
</div>
<section id="connect-on-demand">
<p>The upside of this approach (connecting on first use) is that this will
only open the connection if truly necessary.  If you want to use this
code outside a request context you can use it in a Python shell by opening
the application context by hand:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="k">with</span> <span class="n">app</span><span class="o">.</span><span class="n">app_context</span><span class="p">():</span>
    <span class="c1"># now you can use get_db()</span>
</pre></div>
</div>
</section>
<section id="easy-querying">
<h2>Easy Querying<a class="headerlink" href="https://flask-docs.readthedocs.io/#easy-querying" title="Permalink to this headline">¶</a></h2>
<p>Now in each request handling function you can access <cite>get_db()</cite> to get the
current open database connection.  To simplify working with SQLite, a
row factory function is useful.  It is executed for every result returned
from the database to convert the result.  For instance, in order to get
dictionaries instead of tuples, this could be inserted into the <code class="docutils literal notranslate"><span class="pre">get_db</span></code>
function we created above:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="k">def</span> <span class="nf">make_dicts</span><span class="p">(</span><span class="n">cursor</span><span class="p">,</span> <span class="n">row</span><span class="p">):</span>
    <span class="k">return</span> <span class="nb">dict</span><span class="p">((</span><span class="n">cursor</span><span class="o">.</span><span class="n">description</span><span class="p">[</span><span class="n">idx</span><span class="p">][</span><span class="mi">0</span><span class="p">],</span> <span class="n">value</span><span class="p">)</span>
                <span class="k">for</span> <span class="n">idx</span><span class="p">,</span> <span class="n">value</span> <span class="ow">in</span> <span class="nb">enumerate</span><span class="p">(</span><span class="n">row</span><span class="p">))</span>

<span class="n">db</span><span class="o">.</span><span class="n">row_factory</span> <span class="o">=</span> <span class="n">make_dicts</span>
</pre></div>
</div>
<p>This will make the sqlite3 module return dicts for this database connection, which are much nicer to deal with. Even more simply, we could place this in <code class="docutils literal notranslate"><span class="pre">get_db</span></code> instead:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="n">db</span><span class="o">.</span><span class="n">row_factory</span> <span class="o">=</span> <span class="n">sqlite3</span><span class="o">.</span><span class="n">Row</span>
</pre></div>
</div>
<p>This would use Row objects rather than dicts to return the results of queries. These are <code class="docutils literal notranslate"><span class="pre">namedtuple</span></code> s, so we can access them either by index or by key. For example, assuming we have a <code class="docutils literal notranslate"><span class="pre">sqlite3.Row</span></code> called <code class="docutils literal notranslate"><span class="pre">r</span></code> for the rows <code class="docutils literal notranslate"><span class="pre">id</span></code>, <code class="docutils literal notranslate"><span class="pre">FirstName</span></code>, <code class="docutils literal notranslate"><span class="pre">LastName</span></code>, and <code class="docutils literal notranslate"><span class="pre">MiddleInitial</span></code>:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="gp">&gt;&gt;&gt; </span><span class="c1"># You can get values based on the row&#39;s name</span>
<span class="gp">&gt;&gt;&gt; </span><span class="n">r</span><span class="p">[</span><span class="s1">&#39;FirstName&#39;</span><span class="p">]</span>
<span class="go">John</span>
<span class="gp">&gt;&gt;&gt; </span><span class="c1"># Or, you can get them based on index</span>
<span class="gp">&gt;&gt;&gt; </span><span class="n">r</span><span class="p">[</span><span class="mi">1</span><span class="p">]</span>
<span class="go">John</span>
<span class="go"># Row objects are also iterable:</span>
<span class="gp">&gt;&gt;&gt; </span><span class="k">for</span> <span class="n">value</span> <span class="ow">in</span> <span class="n">r</span><span class="p">:</span>
<span class="gp">... </span>    <span class="nb">print</span><span class="p">(</span><span class="n">value</span><span class="p">)</span>
<span class="go">1</span>
<span class="go">John</span>
<span class="go">Doe</span>
<span class="go">M</span>
</pre></div>
</div>
<p>Additionally, it is a good idea to provide a query function that combines
getting the cursor, executing and fetching the results:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="k">def</span> <span class="nf">query_db</span><span class="p">(</span><span class="n">query</span><span class="p">,</span> <span class="n">args</span><span class="o">=</span><span class="p">(),</span> <span class="n">one</span><span class="o">=</span><span class="kc">False</span><span class="p">):</span>
    <span class="n">cur</span> <span class="o">=</span> <span class="n">get_db</span><span class="p">()</span><span class="o">.</span><span class="n">execute</span><span class="p">(</span><span class="n">query</span><span class="p">,</span> <span class="n">args</span><span class="p">)</span>
    <span class="n">rv</span> <span class="o">=</span> <span class="n">cur</span><span class="o">.</span><span class="n">fetchall</span><span class="p">()</span>
    <span class="n">cur</span><span class="o">.</span><span class="n">close</span><span class="p">()</span>
    <span class="k">return</span> <span class="p">(</span><span class="n">rv</span><span class="p">[</span><span class="mi">0</span><span class="p">]</span> <span class="k">if</span> <span class="n">rv</span> <span class="k">else</span> <span class="kc">None</span><span class="p">)</span> <span class="k">if</span> <span class="n">one</span> <span class="k">else</span> <span class="n">rv</span>
</pre></div>
</div>
<p>This handy little function, in combination with a row factory, makes
working with the database much more pleasant than it is by just using the
raw cursor and connection objects.</p>
<p>Here is how you can use it:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="k">for</span> <span class="n">user</span> <span class="ow">in</span> <span class="n">query_db</span><span class="p">(</span><span class="s1">&#39;select * from users&#39;</span><span class="p">):</span>
    <span class="nb">print</span><span class="p">(</span><span class="n">user</span><span class="p">[</span><span class="s1">&#39;username&#39;</span><span class="p">],</span> <span class="s1">&#39;has the id&#39;</span><span class="p">,</span> <span class="n">user</span><span class="p">[</span><span class="s1">&#39;user_id&#39;</span><span class="p">])</span>
</pre></div>
</div>
<p>Or if you just want a single result:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="n">user</span> <span class="o">=</span> <span class="n">query_db</span><span class="p">(</span><span class="s1">&#39;select * from users where username = ?&#39;</span><span class="p">,</span>
                <span class="p">[</span><span class="n">the_username</span><span class="p">],</span> <span class="n">one</span><span class="o">=</span><span class="kc">True</span><span class="p">)</span>
<span class="k">if</span> <span class="n">user</span> <span class="ow">is</span> <span class="kc">None</span><span class="p">:</span>
    <span class="nb">print</span><span class="p">(</span><span class="s1">&#39;No such user&#39;</span><span class="p">)</span>
<span class="k">else</span><span class="p">:</span>
    <span class="nb">print</span><span class="p">(</span><span class="n">the_username</span><span class="p">,</span> <span class="s1">&#39;has the id&#39;</span><span class="p">,</span> <span class="n">user</span><span class="p">[</span><span class="s1">&#39;user_id&#39;</span><span class="p">])</span>
</pre></div>
</div>
<p>To pass variable parts to the SQL statement, use a question mark in the
statement and pass in the arguments as a list.  Never directly add them to
the SQL statement with string formatting because this makes it possible
to attack the application using <a class="reference external" href="https://en.wikipedia.org/wiki/SQL_injection">SQL Injections</a>.</p>
</section>
<section id="initial-schemas">
<h2>Initial Schemas<a class="headerlink" href="https://flask-docs.readthedocs.io/#initial-schemas" title="Permalink to this headline">¶</a></h2>
<p>Relational databases need schemas, so applications often ship a
<cite>schema.sql</cite> file that creates the database.  It’s a good idea to provide
a function that creates the database based on that schema.  This function
can do that for you:</p>
<div class="highlight-default notranslate"><div class="highlight"><pre><span></span><span class="k">def</span> <span class="nf">init_db</span><span class="p">():</span>
    <span class="k">with</span> <span class="n">app</span><span class="o">.</span><span class="n">app_context</span><span class="p">():</span>
        <span class="n">db</span> <span class="o">=</span> <span class="n">get_db</span><span class="p">()</span>
        <span class="k">with</span> <span class="n">app</span><span class="o">.</span><span class="n">open_resource</span><span class="p">(</span><span class="s1">&#39;schema.sql&#39;</span><span class="p">,</span> <span class="n">mode</span><span class="o">=</span><span class="s1">&#39;r&#39;</span><span class="p">)</span> <span class="k">as</span> <span class="n">f</span><span class="p">:</span>
            <span class="n">db</span><span class="o">.</span><span class="n">cursor</span><span class="p">()</span><span class="o">.</span><span class="n">executescript</span><span class="p">(</span><span class="n">f</span><span class="o">.</span><span class="n">read</span><span class="p">())</span>
        <span class="n">db</span><span class="o">.</span><span class="n">commit</span><span class="p">()</span>
</pre></div>
</div>
<p>You can then create such a database from the Python shell:</p>
<div class="doctest highlight-default notranslate"><div class="highlight"><pre><span></span><span class="gp">&gt;&gt;&gt; </span><span class="kn">from</span> <span class="nn">yourapplication</span> <span class="kn">import</span> <span class="n">init_db</span>
<span class="gp">&gt;&gt;&gt; </span><span class="n">init_db</span><span class="p">()</span>
</pre></div>
</div>
</section>
</section>


            <div class="clearer"></div>
          </div>
        </div>
      </div>
  <span id="sidebar-top"></span>
      <div class="sphinxsidebar" role="navigation" aria-label="main navigation">
        <div class="sphinxsidebarwrapper">
  
    
            <p class="logo"><a href="https://flask-docs.readthedocs.io/en/latest/">
              <img class="logo" src="https://flask-docs.readthedocs.io/en/latest/_static/flask-icon.png" alt="Logo"/>
            </a></p>
  

  <h3>Contents</h3>
  <ul>
<li><a class="reference internal" href="https://flask-docs.readthedocs.io/#">Using SQLite 3 with Flask</a><ul>
<li><a class="reference internal" href="/сессии/Сессия1Илья/app.py" download="app.py">Connect one Demon</a></li>
<li><a class="reference internal" href="/сессии/Сессия 2/app.py" download="app.py">Connect two Demon</a></li>
<li><a class="reference internal" href="/сессии/Сессия 2/templates/add_employee.html" download="add_employee.html">Easy Querying Two</a></li>
<li><a class="reference internal" href="/сессии/Сессия 2/templates/index.html" download="index.html">Initial Schemas Two</a></li>
</ul>
</li>
</ul>
<h3>Navigation</h3>
<ul>
  <li><a href="https://flask-docs.readthedocs.io/../../">Overview 1</a>
    <ul>
      <a>Previous: <a href="/сессии/corporate-directory/app.py" download="app.py" title="previous chapter">Using URL Processors</a></a>
      <ul>Next: <a href="/сессии/corporate-directory/templates/index.html" download="index.html" title="next chapter">SQLAlchemy in Flask</a></ul>
    </ul>
  </li>
</ul>
<ul>
  <li><a href="https://flask-docs.readthedocs.io/../../">Processors 2</a>
    <ul>
      <li>Previous: <a href="/сессии/sesss2/app.py" download="app.py" title="previous chapter">Previous: Using URL</a></li>
      <li>Next: <a href="/сессии/sesss2/templates/index.html" download="index.html" title="next chapter">Next: SQLAlchemy in Flask</a></li>
    </ul>
  </li>
</ul>
<div id="searchbox" role="search">
  <h3 id="searchlabel">Quick search</h3>
    <div class="searchformwrapper">
    <form class="search" action="../../search/" method="get">
      <input type="text" name="q" aria-labelledby="searchlabel" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"/>
      <input type="submit" value="Go" />
    </form>
    </div>
</div>
<script>$('#searchbox').show(0);</script><div id="ethical-ad-placement"></div>
        </div>
      </div>
      <div class="clearer"></div>
    </div>
  
    <div class="footer" role="contentinfo">
        &#169; Copyright 2010 Pallets.
      Created using <a href="https://www.sphinx-doc.org/">Sphinx</a> 4.5.0.
    </div>
    </>
    )
  }
}
